<script setup>
import { useStoreStore } from "@stores/store.js";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

const global = useStoreStore();

const breakpoints = ref({
  0: {
    slidesPerView: 1,
  },
  768: {
    slidesPerView: 2,
    spaceBetween: "5",
  },
  1024: {
    slidesPerView: 3,
    spaceBetween: "5",
  },
  1400: {
    slidesPerView: 4,
    spaceBetween: "5",
  },
});
</script>
<template>
  <section class="container">
    <swiper
      :modules="[Navigation]"
      :navigation="true"
      :breakpoints="breakpoints"
      class="small-arrows">
      <swiper-slide
        class="collection-type"
        v-for="item in global.showcasedCollections"
        :key="item.title">
        <router-link
          :to="{ name: 'collection', params: { id: item.slug } }"
          class="flex w-full min-w-[12rem] items-center justify-start gap-3 rounded-big border border-border-default px-8 py-3 text-default hover:border-border-default hover:bg-neutral-700">
          <!--          <i :class="item.icon" />-->
          <img :src="item.icon" :width="24" :height="24" alt="icon" />
          <span class="label-medium min-w-fit">{{ item.title }}</span>
        </router-link>
      </swiper-slide>
    </swiper>
  </section>
</template>

<style scoped>
.swiper-slide {
  @apply min-w-fit;
}

@media (max-width: 600px) {
  .collection-type {
    margin-right: 10% !important;
    @apply ml-[10%] max-w-[80%];
  }
}
</style>
