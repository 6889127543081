<script setup>
import { useAuthStore } from "@stores/auth";
import { onMounted, ref } from "vue";

import Button from "@components/global/button.vue";
import Input from "@components/global/input.vue";

const auth = useAuthStore();
const processing = ref(false);
const profile = ref({});
const errors = ref({});

function prefilProfile() {
	profile.value.username = auth.user?.username;
	profile.value.name = auth.user?.name;
	profile.value.biography = auth.user?.biography;
	//
	profile.value.social_twitter = auth.user?.social_twitter ?? null;
	profile.value.social_instagram = auth.user?.social_instagram ?? null;
	profile.value.social_youtube = auth.user?.social_youtube ?? null;
	profile.value.social_facebook = auth.user?.social_facebook ?? null;
}

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		auth.updateProfile(profile.value)
			.then(() => {
				errors.value = {};
				processing.value = false;
				prefilProfile();
			})
			.catch((errorBag) => {
				processing.value = false;
				errors.value = errorBag;
			});
	}
}

onMounted(() => {
	prefilProfile();
});
</script>

<template>
	<form class="flex w-full flex-col gap-8 rounded-max border border-border-default px-8 py-6 text-default" action="" @submit="submit">
		<h4 class="uppercase">My profile</h4>
		<!-- <div class="relative sm:h-[25rem] h-[20rem]">
      <img
        class="h-[15rem] w-full rounded-max"
        :src="user.bg_image"
        alt="profile-background image" />
      <Button without-edge="true" class="btn-edit">
        <i class="fa-regular fa-pen-to-square"></i>
      </Button>
      <div
        class="absolute left-[50%] top-[12rem] z-images translate-x-[-50%] rounded-max bg-bg p-1">
        <div class="relative">
          <img
            class="w-full max-w-[10rem] rounded-max"
            :src="user.profile_image"
            alt="profile image" />
          <Button without-edge="true" class="btn-edit">
            <i class="fa-regular fa-pen-to-square"></i>
          </Button>
        </div>
      </div>
    </div> -->
		<div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
			<Input label="username" placeholder="Insert your username" filter="username" max="20" :required="true" v-model="profile.username" :error="errors.username && errors.username[0]" />
			<Input placeholder="Insert your name" label="display name" max="32" v-model="profile.name" :error="errors.name && errors.name[0]" />
			<Input class="lg:col-span-2" placeholder="Insert your bio" max="300" label="bio" v-model="profile.biography" :error="errors.biography && errors.biography[0]" />
			<Input placeholder="https://x.com/ether" label="X" v-model="profile.social_twitter" :error="errors.social_twitter && errors.social_twitter[0]" />
			<Input placeholder="https://instagram.com/ether" label="instagram" v-model="profile.social_instagram" :error="errors.social_instagram && errors.social_instagram[0]" />
			<Input placeholder="https://youtube.com/ether" label="youtube" v-model="profile.social_youtube" :error="errors.social_youtube && errors.social_youtube[0]" />
			<Input placeholder="https://facebook.com/ether" label="facebook" v-model="profile.social_facebook" :error="errors.social_facebook && errors.social_facebook[0]" />
		</div>
		<Button class="default w-full" :processing="processing">Save changes</Button>
	</form>
</template>

<style scoped>
.btn-edit {
	@apply absolute right-2 top-2 z-images h-12 w-12 rounded-big bg-neutral-500 text-lg opacity-90;
}
</style>
