<script setup>
import { format } from "@formkit/tempo";
import { useGlobalStore } from "@stores/global";
import Markdown from "vue3-markdown-it";

const global = useGlobalStore();
</script>

<template>
	<section class="container">
		<div v-for="update in global.storyUpdates" class="content flex flex-col gap-8 lg:flex-row">
			<div :class="['sticky top-[7rem] z-[5] flex h-fit w-full flex-col gap-3 bg-bg pt-4 text-default lg:top-[9rem] lg:w-half']">
				<h5 class="uppercase">{{ update.title }}</h5>
				<span class="label-medium opacity-30">{{ format(update.published_at, "MMM D, YYYY") }}</span>
				<span class="h-[1px] w-full bg-border-default" />
				<Markdown class="markdown hidden lg:block" :source="update.description" :breaks="true" />
			</div>
			<div class="text-default lg:hidden">
				<Markdown class="markdown" :source="update.description" :breaks="true" />
			</div>
			<div class="flex w-full flex-col gap-4 lg:w-half">
				<div v-for="(image, i) in update.images" :key="i" class="card sticky rounded-max bg-border-default p-1">
					<img :src="image.image" class="w-full rounded-max" alt="image" />
				</div>
			</div>
		</div>
	</section>
</template>

<style scoped>
.card:not(:last-child) {
	@apply top-[15rem] lg:top-[10rem];
}

.content:not(:first-child) {
	@apply mt-40;
}
</style>
