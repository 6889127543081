<script lang="js" setup>
import { useGlobalStore } from "@stores/global";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

const global = useGlobalStore();

const breakpoints = ref({
	0: {
		slidesPerView: 1,
	},
	1000: {
		slidesPerView: Math.min(global.characters.length, 2),
		spaceBetween: 4,
	},
});

const activeIndex = ref(0);

const activeItems = () => {
	return global.characters.slice(activeIndex.value, activeIndex.value + 5);
};

const handleNext = () => {
	if (activeIndex.value > global.characters.length) return;

	activeIndex.value++;
};

const handlePrev = () => {
	if (activeIndex.value < 0) return;

	activeIndex.value--;
};
</script>

<template>
	<section class="container flex flex-col gap-7 text-default">
		<div class="hidden justify-center gap-5 xl:flex">
			<Button v-if="global.characters.length > 5" @click="handlePrev()" class="outlined h-fit self-center px-6 py-[14px]" :disabled="activeIndex === 0" :without-edge="true">
				<i class="icon-arrow-left" />
			</Button>
			<div class="char-container animation flex h-[570px] justify-center gap-3">
				<div class="char-card animation relative flex-grow overflow-hidden" v-for="character in activeItems()">
					<div class="animation h-full">
						<div class="delimiter absolute left-0 top-0 w-[800px] opacity-0">
							<img alt="bg" class="h-[200px]" src="https://cdn.ether.site/images/separation-section.svg" />
						</div>
						<img class="animation h-full origin-bottom-left scale-100 object-cover" alt="hero-image" :src="character.avatar" />
					</div>

					<div class="char-content animation absolute right-[-100%] flex flex-col items-end gap-7 pr-8 pt-8 text-default opacity-0">
						<h1 class="uppercase">{{ character.name }}</h1>
						<div class="flex flex-col items-end gap-10 p-2">
							<span class="label-small w-[250px]">
								{{ character.description }}
							</span>
							<div class="grid grid-cols-2 gap-2">
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl">
									<i class="fa-solid fa-passport"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Name</span>
										<span class="label-medium">{{ character.name }}</span>
									</div>
								</div>
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.gender">
									<i class="fa-solid fa-venus-mars"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Gender</span>
										<span class="label-medium">{{ character.gender }}</span>
									</div>
								</div>
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.height">
									<i class="fa-solid fa-ruler-vertical"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Height</span>
										<span class="label-medium">{{ character.height }}</span>
									</div>
								</div>
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.power">
									<i class="fa-solid fa-bolt-lightning"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Power</span>
										<span class="label-medium">{{ character.power }}</span>
									</div>
								</div>
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.likes">
									<i class="fa-solid fa-heart"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Likes</span>
										<span class="label-medium">{{ character.likes }}</span>
									</div>
								</div>
								<div class="flex w-[210px] flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.dislikes">
									<i class="fa-solid fa-heart-crack"></i>
									<div class="flex flex-col gap-0">
										<span class="label-small opacity-30">Dislikes</span>
										<span class="label-medium">{{ character.dislikes }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Button v-if="global.characters.length > 5" @click="handleNext()" class="outlined h-fit self-center px-6 py-[14px]" :disabled="activeIndex + Math.min(global.characters.length, 5) === global.characters.length" :without-edge="true">
				<i class="icon-arrow-right" />
			</Button>
		</div>

		<div class="flex w-full xl:hidden">
			<swiper :modules="[Navigation]" :navigation="true" :breakpoints="breakpoints">
				<swiper-slide v-for="character in global.characters">
					<div class="char-card animation flex-grow overflow-hidden lg:max-h-[550px]">
						<div class="animation relative h-full">
							<div class="delimiter absolute left-0 top-0 w-[800px] opacity-0">
								<img alt="bg" class="h-[200px]" src="https://cdn.ether.site/images/separation-section.svg" />
							</div>
							<img class="animation h-full origin-bottom-left scale-100 object-cover" alt="hero-image" :src="character.avatar" />
						</div>

						<div class="char-content animation absolute bottom-5 right-[-100%] flex h-full w-full flex-col items-end justify-end gap-2 p-3 text-default opacity-0 backdrop-blur-lg lg:gap-7 lg:pr-8 lg:pt-8">
							<h1 class="w-full text-center uppercase xl:w-auto xl:text-end">{{ character.name }}</h1>
							<div class="flex w-full flex-col items-end gap-4 p-2 lg:gap-10">
								<span class="label-small w-full px-6 text-center xl:w-[250px] xl:px-0 xl:text-start">
									{{ character.description }}
								</span>
								<div class="grid w-full grid-cols-2 gap-2">
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl">
										<i class="fa-solid fa-passport"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Name</span>
											<span class="label-medium">{{ character.name }}</span>
										</div>
									</div>
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.gender">
										<i class="fa-solid fa-venus-mars"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Gender</span>
											<span class="label-medium">{{ character.gender }}</span>
										</div>
									</div>
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.height">
										<i class="fa-solid fa-ruler-vertical"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Height</span>
											<span class="label-medium">{{ character.height }}</span>
										</div>
									</div>
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.power">
										<i class="fa-solid fa-bolt-lightning"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Power</span>
											<span class="label-medium">{{ character.power }}</span>
										</div>
									</div>
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.likes">
										<i class="fa-solid fa-heart"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Likes</span>
											<span class="label-medium">{{ character.likes }}</span>
										</div>
									</div>
									<div class="flex w-full flex-row items-center gap-2 rounded-xl border border-neutral-500 bg-neutral-700 p-4 text-2xl" v-if="character.dislikes">
										<i class="fa-solid fa-heart-crack"></i>
										<div class="flex flex-col gap-0">
											<span class="label-small opacity-30">Dislikes</span>
											<span class="label-medium">{{ character.dislikes }}</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</swiper-slide>
			</swiper>
		</div>
	</section>
</template>

<style scoped>
.container {
	@apply px-0;
}
.char-container:hover .char-card {
	@apply basis-[160px] brightness-75 grayscale;
}

.char-card {
	@apply flex basis-[260px] flex-row overflow-hidden rounded-lg;
	background: radial-gradient(78.05% 118.54% at 28.32% -2.53%, #343434 0%, #1c1c1c 100%);
}

.char-card:hover .delimiter {
	@apply opacity-100;
}

.char-container:hover .char-card:hover {
	@apply basis-[600px] bg-border-default brightness-100 grayscale-0;
}

.char-card:hover img {
	@apply ml-[-20%] scale-[1.03];
}

.char-card:hover .char-content {
	@apply right-0 opacity-100;
}
</style>
