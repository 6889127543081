<script setup>
import { useGlobalStore } from "@stores/global";
import { useFocus } from "@vueuse/core";
import { nextTick, ref } from "vue";

const global = useGlobalStore();
const props = defineProps(["modelValue", "filter", "type", "required", "placeholder", "disabled", "max", "label", "icon", "error", "autocomplete", "spellcheck", "autocapitalize", "forgot"]);
const emit = defineEmits(["update:modelValue"]);

const input = ref();
const { focused } = useFocus(input);
const passwordVisible = ref(false);
const ov = ref(null);
const oss = ref(null);
const ose = ref(null);
const handlers = ref({
	input: handleInput,
	keyup: handleInput,
	keydown: handleInput,
	select: handleInput,
	contextmenu: handleInput,
	drop: handleInput,
});

function handleInput(event) {
	if (props.filter == "username") {
		if (/^[a-zA-Z0-9_ ]*$/i.test(input.value.value)) {
			ov.value = input.value.value.replace(/-+/g, "").replace(/\s+/g, "_").replace(/\_\_+/g, "_").replace(/^_+/, "");
			oss.value = input.value.selectionStart;
			ose.value = input.value.selectionEnd;
			input.value.value = ov.value;
			emit("update:modelValue", ov.value);
		} else if (ov.value) {
			input.value.value = ov.value;
			emit("update:modelValue", ov.value);
			input.value.setSelectionRange(oss.value, ose.value);
		} else {
			input.value.value = "";
			emit("update:modelValue", "");
		}
	} else if (props.filter == "phone") {
		if (/^[0-9+()-]*$/i.test(input.value.value)) {
			ov.value = input.value.value.replace(/\s+/g, "-").replace(/\-\-+/g, "-").replace(/^-+/, "");
			oss.value = input.value.selectionStart;
			ose.value = input.value.selectionEnd;
			input.value.value = ov.value;
			emit("update:modelValue", ov.value);
		} else if (ov.value) {
			input.value.value = ov.value;
			emit("update:modelValue", ov.value);
			input.setSelectionRange(oss.value, ose.value);
		} else {
			input.value.value = "";
			emit("update:modelValue", "");
		}
	} else {
		emit("update:modelValue", event.target.value);
	}
}

const toggleVisible = async () => {
	passwordVisible.value = !passwordVisible.value;
	await nextTick();
	input.value.focus();
};
</script>

<template>
	<div class="flex w-full flex-col gap-2 normal-case">
		<div class="flex justify-between">
			<label class="label-medium text-sm opacity-50" v-if="label">
				{{ label }}
			</label>
			<span class="label-medium cursor-pointer text-purple-base" @click="global.openModal(['forgot-password'])" v-if="forgot">Forgot password?</span>
		</div>
		<div class="relative flex w-full">
			<i :class="'text-neutral-600 absolute left-6 top-1/2 -translate-y-1/2 text-sm ' + icon"></i>
			<input :class="{ 'icon-padding': icon }" :type="passwordVisible ? 'text' : type || 'text'" :placeholder="placeholder" :maxlength="max" :required="required" v-on="handlers" :value="modelValue" :autocomplete="autocomplete" :disabled="disabled" :spellcheck="spellcheck" :autocapitalize="autocapitalize" ref="input" />
			<button class="ml-1 flex w-14 items-center justify-center rounded-[12px] border border-border-default bg-neutral-700" v-if="type == 'password'" @click="toggleVisible()">
				<i :class="['far', passwordVisible ? 'fa-eye' : 'fa-eye-slash']"></i>
			</button>
		</div>
		<p class="text-red-500 text-xs" v-if="error">{{ error }}</p>
	</div>
</template>

<style scoped>
input {
	@apply w-full rounded-[12px] border border-border-default bg-neutral-700 p-3 font-worksans normal-case placeholder:normal-case placeholder:opacity-70 focus:outline-none;
}

.icon-padding {
	@apply pl-12;
}
</style>
