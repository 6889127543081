<script lang="ts" setup>
import ProductItem from "@sections/shop-list/product-item.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import Button from "@components/global/button.vue";

const props = defineProps(["collection", "cta"]);

const slider = ref({});
const onSwiper = (swiper) => {
	slider.value = swiper;
};

const breakpoints = {
	0: { spaceBetween: 0, slidesPerView: 1 },
	700: { spaceBetween: 20, slidesPerView: 2 },
	1024: { spaceBetween: 20, slidesPerView: 3 },
	1500: { spaceBetween: 24, slidesPerView: 4 },
};
</script>

<template>
	<div class="container gap-6 pt-10 lg:gap-12 lg:pt-20" v-if="collection && collection.products?.length">
		<div class="flex items-center justify-between uppercase text-default">
			<h4>{{ collection.title }}</h4>
			<div class="flex gap-2 text-[20аpx]">
				<Button @click="slider.slidePrev()" class="outlined px-6 py-[14px]" :without-edge="true">
					<i class="icon-arrow-left" />
				</Button>
				<Button @click="slider.slideNext()" class="outlined px-6 py-[14px]" :without-edge="true">
					<i class="icon-arrow-right" />
				</Button>
			</div>
		</div>

		<Swiper class="mt-12" @swiper="onSwiper" :slides-per-view="4" :space-between="24" :breakpoints="breakpoints" :allowTouchMove="false" :loop="true">
			<SwiperSlide v-for="(product, i) in collection.products" :key="product.title">
				<ProductItem :item="product" />
			</SwiperSlide>
		</Swiper>

		<div class="mt-[5rem] w-full lg:mt-[7.5rem]" v-if="cta.show">
			<div class="relative flex justify-center">
				<div class="absolute left-0 right-0 top-[50%] h-[1px] bg-border-default" />
				<div class="relative z-lines w-fit bg-bg px-4 lg:px-8">
					<Button class="outlined flex justify-center text-default lg:w-[400px]" :to="{ name: 'collection', params: { id: props.collection.slug } }" icon="fa-solid fa-arrow-up-right text-base leading-4">
						<span type="label-medium">{{ cta.title }}</span>
					</Button>
				</div>
			</div>
		</div>
	</div>
</template>
