<script setup>
import { useStoreStore } from "@stores/store";

import Button from "@components/global/button.vue";

const store = useStoreStore();
</script>
<template>
	<section class="container" v-if="store.featuredCollection.featured">
		<div class="relative flex h-full w-full flex-col items-center text-default lg:flex-row lg:justify-end">
			<img class="absolute left-0 top-0 hidden w-[50%] lg:block" src="https://cdn.ether.site/images/home-page/home-who-we-are-dots.svg" alt="" />
			<div class="z-images flex w-full flex-col items-center gap-6 rounded-[12px] bg-bg p-0 pb-4 text-center lg:w-[30%] lg:items-start lg:pb-12 lg:pr-12 lg:pt-12 lg:text-start">
				<div class="label-small flex w-fit items-center rounded-big border border-border-default bg-neutral-700 px-2 py-1" v-if="store.featuredCollection.featured.subtitle">{{ store.featuredCollection.featured.subtitle }}</div>
				<h2 class="flex flex-col uppercase" v-if="store.featuredCollection.featured.title">
					<span>{{ store.featuredCollection.featured.title }}</span>
				</h2>
				<p class="md normal-case" v-if="store.featuredCollection.featured.description">{{ store.featuredCollection.featured.description }}</p>
				<Button with-icon class="outlined w-fit uppercase" :to="{ name: 'collection', params: { id: store.featuredCollection.slug } }" v-if="store.featuredCollection.slug">
					<span class="label-medium">See collection</span>
				</Button>
			</div>
			<img class="relative w-full lg:w-[70%]" :src="store.featuredCollection.featured.image" alt="Collection Image" v-if="store.featuredCollection.featured.image" />
		</div>
	</section>
</template>
