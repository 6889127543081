<template>
	<div class="flex flex-col lg:flex-row">
		<img src="https://cdn.ether.site/images/about-page/main.png" alt="image" class="lg:w-hal w-full object-cover lg:mt-[-200px]" />
		<div class="flex w-full flex-col gap-8 border-b border-border-default p-10 text-default lg:min-h-[600px] lg:w-half lg:justify-center">
			<h4 class="text-center uppercase lg:text-left">What is ETHER?</h4>
			<p class="md opacity-70">ETHER, created by V, is a brand that bridges fashion, storytelling, technology, and anime. We craft unique experiences that blend the physical and digital worlds, all shaped by our community.</p>
			<p class="md opacity-70">At ETHER, we create with passion and precision all while being a fluid brand that constantly challenges and expands its horizons - An evolving brand.</p>
			<p class="md opacity-70">ETHER launched officially in July 2022. Since then, we have become a community of over 90,000 fans on social media and DISCORD. Our journey is just beginning.</p>
		</div>
	</div>
</template>
<script setup lang="ts"></script>
