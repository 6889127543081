import { defineStore } from "pinia";

export const useGlobalStore = defineStore("global", {
	state: () => {
		return {
			mobile: false,
			cart: false,
			modal: {
				name: null,
				payload: null,
			},
			announcement: null,
			heroShowcase: null,
			socials: [],
			characters: [],
			storyUpdates: [],
			supporters: [],
			news: newsData,
			article: newsData[3],
			privacy: {},
			terms: {},
		};
	},
	actions: {
		toggleMobile(delta) {
			delta != null ? (this.mobile = delta) : (this.mobile = !this.mobile);
		},
		toggleCart(delta) {
			delta != null ? (this.cart = delta) : (this.cart = !this.cart);
		},
		setup() {
			this.getSocials();
			this.getAnnouncement();
			this.getHeroShowcase();
			this.getCharacters();
			this.getStoryUpdates();
			this.getSupporters();
			//   this.getTerms();
		},
		//
		getSocials() {
			return axios
				.get("/api/socials")
				.then((socials) => {
					this.socials = socials.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getAnnouncement() {
			return axios
				.get("/api/announcement")
				.then((announcement) => {
					this.announcement = announcement.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getHeroShowcase() {
			return axios
				.get("/api/hero-showcase")
				.then((heroShowcase) => {
					this.heroShowcase = heroShowcase.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getCharacters() {
			return axios
				.get("/api/characters")
				.then((characters) => {
					this.characters = characters.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getStoryUpdates() {
			return axios
				.get("/api/story-updates")
				.then((storyUpdates) => {
					this.storyUpdates = storyUpdates.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getSupporters() {
			return axios
				.get("/api/supporters")
				.then((supporters) => {
					this.supporters = supporters.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getPrivacy() {
			return axios
				.get("https://sentinels.gg/api/page/privacy")
				.then((socials) => {
					this.privacy = socials.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getTerms() {
			return axios
				.get("https://sentinels.gg/api/page/terms")
				.then((socials) => {
					this.terms = socials.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		openModal(modal) {
			this.modal = {
				name: modal[0],
				payload: modal[1],
			};
		},
		closeModal() {
			this.modal = {
				name: null,
				payload: null,
			};
		},
	},
});

const newsData = [
	{
		title: "At the end of the battle, an invite went out the cosmos",
		body: "",
		preview:
			"Dolore proident cillum cupidatat dolor. Reprehenderit mollit sunt in fugiat proident. Dolor dolore anim enim reprehenderit aliqui lorem ipsum dolor.",
		slug: "watch-sentinels-road-to-redemption-in-the-vct-2024-season",
		image_path: "https://cdn.ether.site/images/news-page/news-image-1.png",
		external_link: "https://www.oneesports.gg/valorant/sentinels-road-to-redemption-vct-2024/",
		published_at: "2024-03-01T14:00:00.000000Z",
		featured: true,
		snippet: null,
		categories: [
			{
				name: "Valorant",
				slug: "valorant",
			},
		],
	},
	{
		title: "WL Mint Phase starts tomorrow:30th June, 8am PST",
		preview:
			"Dolore proident cillum cupidatat dolor. Reprehenderit mollit sunt in fugiat proident. Dolor dolore anim enim reprehenderit aliqui lorem ipsum dolor.",
		slug: "watch-sentinels-road-to-redemption-in-the-vct-2024-season",
		image_path: "https://cdn.ether.site/images/news-page/news-image-2.png",
		external_link: "https://www.oneesports.gg/valorant/sentinels-road-to-redemption-vct-2024/",
		published_at: "2024-03-01T14:00:00.000000Z",
		featured: true,
		snippet: null,
		categories: [
			{
				name: "Valorant",
				slug: "valorant",
			},
		],
	},
	{
		title: "The time has come...",
		preview:
			"Dolore proident cillum cupidatat dolor. Reprehenderit mollit sunt in fugiat proident. Dolor dolore anim enim reprehenderit aliqui lorem ipsum dolor.",
		slug: "sentinels-team-capsule-available-now",
		image_path: "https://cdn.ether.site/images/news-page/news-image-3.png",
		external_link: "https://www.oneesports.gg/valorant/sentinels-road-to-redemption-vct-2024/",
		published_at: "2024-03-01T14:00:00.000000Z",
		featured: true,
		snippet: null,
		categories: [
			{
				name: "Valorant",
				slug: "valorant",
			},
		],
	},
	{
		title: "This announcement addresses the reveal and our changed mint structure",
		body: `Qui occaecat laboris officia pariatur labore proident aliquip sit reprehenderit pariatur veniam veniam voluptate enim. Aliquip ut et duis id aute enim commodo. Laboris pariatur occaecat nostrud qui. Esse mollit magna culpa cillum id duis culpa qui ullamco culpa aute est minim cillum. Qui irure anim anim sint sunt cillum voluptate cupidatat duis excepteur commodo ipsum veniam enim. Nulla fugiat quis elit laboris elit veniam enim. Do magna mollit esse. Mollit proident amet ipsum enim mollit.
\r\n\r\nAmet esse anim incididunt aute dolore irure adipisicing. Pariatur ex adipisicing quis irure anim sit commodo sit proident enim ut ipsum. Ea commodo minim mollit qui ut adipisicing. Mollit laboris veniam eu elit ex. Est excepteur cupidatat minim sint.
\r\n\r\nDuis tempor mollit sunt eu enim ullamco. Elit quis adipisicing ut occaecat ad amet adipisicing Lorem labore consectetur dolor labore incididunt. Labore et irure velit laborum exercitation dolor aliqua proident anim id incididunt commodo duis ex labore. Et deserunt reprehenderit ipsum consequat nostrud magna proident. Incididunt anim voluptate irure aliqua. Et do id officia adipisicing tempor excepteur minim laboris laboris ipsum qui mollit deserunt pariatur qui. Elit anim veniam commodo labore eiusmod.
\r\n\r\n
![image](https://cdn.ether.site/images/news-page/article-image-1.png)
Qui occaecat laboris officia pariatur labore proident aliquip sit reprehenderit pariatur veniam veniam voluptate enim. Aliquip ut et duis id aute enim commodo. Laboris pariatur occaecat nostrud qui. Esse mollit magna culpa cillum id duis culpa qui ullamco culpa aute est minim cillum. Qui irure anim anim sint sunt cillum voluptate cupidatat duis excepteur commodo ipsum veniam enim. Nulla fugiat quis elit laboris elit veniam enim. Do magna mollit esse. Mollit proident amet ipsum enim mollit.
\r\n\r\nAmet esse anim incididunt aute dolore irure adipisicing. Pariatur ex adipisicing quis irure anim sit commodo sit proident enim ut ipsum. Ea commodo minim mollit qui ut adipisicing. Mollit laboris veniam eu elit ex. Est excepteur cupidatat minim sint.
\r\n\r\nDuis tempor mollit sunt eu enim ullamco. Elit quis adipisicing ut occaecat ad amet adipisicing Lorem labore consectetur dolor labore incididunt. Labore et irure velit laborum exercitation dolor aliqua proident anim id incididunt commodo duis ex labore. Et deserunt reprehenderit ipsum consequat nostrud magna proident. Incididunt anim voluptate irure aliqua. Et do id officia adipisicing tempor excepteur minim laboris laboris ipsum qui mollit deserunt pariatur qui. Elit anim veniam commodo labore eiusmod.
\r\n\r\n
![image](https://cdn.ether.site/images/news-page/article-image-2.png)
![image](https://cdn.ether.site/images/news-page/article-image-3.png)
Qui occaecat laboris officia pariatur labore proident aliquip sit reprehenderit pariatur veniam veniam voluptate enim. Aliquip ut et duis id aute enim commodo. Laboris pariatur occaecat nostrud qui. Esse mollit magna culpa cillum id duis culpa qui ullamco culpa aute est minim cillum. Qui irure anim anim sint sunt cillum voluptate cupidatat duis excepteur commodo ipsum veniam enim. Nulla fugiat quis elit laboris elit veniam enim. Do magna mollit esse. Mollit proident amet ipsum enim mollit.
\r\n\r\nAmet esse anim incididunt aute dolore irure adipisicing. Pariatur ex adipisicing quis irure anim sit commodo sit proident enim ut ipsum. Ea commodo minim mollit qui ut adipisicing. Mollit laboris veniam eu elit ex. Est excepteur cupidatat minim sint.
\r\n\r\nDuis tempor mollit sunt eu enim ullamco. Elit quis adipisicing ut occaecat ad amet adipisicing Lorem labore consectetur dolor labore incididunt. Labore et irure velit laborum exercitation dolor aliqua proident anim id incididunt commodo duis ex labore. Et deserunt reprehenderit ipsum consequat nostrud magna proident. Incididunt anim voluptate irure aliqua. Et do id officia adipisicing tempor excepteur minim laboris laboris ipsum qui mollit deserunt pariatur qui. Elit anim veniam commodo labore eiusmod.
\r\n\r\n
`,
		preview:
			"Dolore proident cillum cupidatat dolor. Reprehenderit mollit sunt in fugiat proident. Dolor dolore anim enim reprehenderit aliqui lorem ipsum dolor.",
		slug: "sentinels-victorious-at-afreecatv-valorant-league-tournament-following-3-0-grand-finals-victory-against-vct-champions-runners-up-paper-rex",
		image_path: "https://cdn.ether.site/images/news-page/news-hero-image.png",
		external_link: "https://www.oneesports.gg/valorant/sentinels-road-to-redemption-vct-2024/",
		published_at: "2024-03-01T14:00:00.000000Z",
		featured: true,
		snippet: null,
		categories: [
			{
				name: "Valorant",
				slug: "valorant",
			},
		],
	},
];
