<template>
  <div
    class="flex w-full flex-col gap-8 rounded-max border border-border-default px-8 py-6 text-default">
    <h4 class="uppercase">my orders</h4>
    <div
      v-for="order in user.orders"
      class="flex w-full items-center gap-4 rounded-max border border-border-default p-2">
      <div class="rounded-max border border-border-default bg-neutral-700 p-4">
        <img class="h-full w-[4rem]" :src="order.img" alt="order image" />
      </div>
      <div class="flex flex-col">
        <p class="md">
          <span v-for="item in order.items" class="text-semicolon">
            ({{ item.total }}) {{ item.name }}
          </span>
        </p>
        <p class="md text-purple-base">Total: {{ order.total }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProfileStore } from "@stores/profile.js";

const user = useProfileStore();
</script>

<style scoped>
.text-semicolon:last-child::after {
  content: none;
}

.text-semicolon::after {
  content: ",";
}
</style>
