<script setup lang="ts">
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { ref } from "vue";

import Button from "@components/global/button.vue";
import Input from "@components/global/input.vue";

const global = useGlobalStore();
const auth = useAuthStore();
const processing = ref(false);
const user = ref({
	//remember: true,
});
const errors = ref({});

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		auth.login(user.value).catch((errorBag) => {
			processing.value = false;
			errors.value = errorBag;
		});
	}
}
</script>
<template>
	<div class="modal-overlay" v-if="global.modal?.name === 'login'" @click="global.closeModal()">
		<div @click="$event.stopPropagation()" class="modal-content max-w-[25rem]">
			<div class="flex items-center justify-between p-4 uppercase">
				<i class="icon-logo text-4xl" />
				<span class="label-medium">login</span>
				<Button class="close" without-edge="true" @click="global.closeModal()">
					<i class="fa fa-xmark" />
				</Button>
			</div>
			<form class="flex w-full flex-col gap-6 rounded-max border border-border-default bg-neutral-700 p-4" action="" @submit="submit">
				<Input label="username" placeholder="Insert your username" filter="username" max="20" :required="true" v-model="user.username" :error="errors.username && errors.username[0]" />
				<Input label="password" type="password" placeholder="Insert your password" :forgot="true" v-model="user.password" :error="errors.password && errors.password[0]" />
				<Button class="default" :processing="processing">login</Button>
				<span class="label-medium self-center">
					Don't have an account?
					<span class="cursor-pointer text-purple-base" @click="global.openModal(['sign-up'])"> Sign up </span>
				</span>
				<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
			</form>
		</div>
	</div>
</template>
