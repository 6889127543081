<script setup>
import NewsCard from "@sections/news/news-card.vue";
import { useGlobalStore } from "@stores/global.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import Button from "@components/global/button.vue";

const global = useGlobalStore();

const slider = ref({});
const onSwiper = (swiper) => {
  slider.value = swiper;
};

const breakpoints = {
  0: { spaceBetween: 0, slidesPerView: 1 },
  1024: { spaceBetween: 20, slidesPerView: 2 },
  1280: { spaceBetween: 20, slidesPerView: 3 },
};
</script>
<template>
  <div class="container gap-6 pt-10 lg:gap-12 lg:pt-20">
    <div class="flex items-center justify-between uppercase text-default">
      <h4>Related articles</h4>
      <div class="flex gap-2 text-[20аpx]">
        <Button
          @click="slider.slidePrev()"
          class="outlined px-6 py-[14px]"
          :without-edge="true">
          <i class="icon-arrow-left" />
        </Button>
        <Button
          @click="slider.slideNext()"
          class="outlined px-6 py-[14px]"
          :without-edge="true">
          <i class="icon-arrow-right" />
        </Button>
      </div>
    </div>

    <Swiper
      class="mt-12"
      @swiper="onSwiper"
      :breakpoints="breakpoints"
      :allowTouchMove="false"
      :loop="true">
      <SwiperSlide v-for="article in global.news" :key="article.title">
        <Button
          :without-edge="true"
          :to="{ name: 'article', params: { slug: article.slug } }">
          <NewsCard :item="article" />
        </Button>
      </SwiperSlide>
    </Swiper>
  </div>
</template>
