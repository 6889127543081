<script setup>
import { format } from "@formkit/tempo";
import { useAuthStore } from "@stores/auth";
import { onMounted, ref } from "vue";

import Button from "@components/global/button.vue";
import Input from "@components/global/input.vue";

const auth = useAuthStore();
const processing = ref(false);
const account = ref({});
const errors = ref({});

function prefilAccount() {
	account.value.email = auth.user?.email;
	account.value.phone = auth.user?.phone;
	account.value.birthday = auth.user?.birthday ? format(auth.user.birthday, "YYYY-MM-DD", "en") : null;
}

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		auth.updateAccount(account.value)
			.then(() => {
				errors.value = {};
				processing.value = false;
				prefilAccount();
			})
			.catch((errorBag) => {
				processing.value = false;
				errors.value = errorBag;
			});
	}
}

onMounted(() => {
	prefilAccount();
});
</script>

<template>
	<form class="flex w-full flex-col gap-8 rounded-max border border-border-default px-8 py-6 text-default" action="" @submit="submit">
		<h4 class="uppercase">Personal Information</h4>
		<div class="grid grid-cols-1 gap-4 lg:grid-cols-2">
			<Input class="lg:col-span-2" placeholder="Insert your email" label="email" type="email" :required="true" v-model="account.email" :error="errors.email && errors.email[0]" />
			<Input placeholder="Insert phone" label="phone number" max="13" filter="phone" v-model="account.phone" :error="errors.phone && errors.phone[0]" />
			<Input placeholder="DD-MM-YYYY" label="birthday" type="date" v-model="account.birthday" :error="errors.birthday && errors.birthday[0]" />
			<Input placeholder="**********" label="Password" type="password" v-model="account.password" :error="errors.password && errors.password[0]" />
			<Input placeholder="**********" label="Confirm Password" type="password" v-model="account.password_confirmation" :error="errors.password_confirmation && errors.password_confirmation[0]" />
		</div>
		<Button class="default w-full" :processing="processing">Save Changes</Button>
	</form>
</template>
