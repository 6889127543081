<script setup>
import StoryChars from "@sections/story/story-chars.vue";
import StoryInfo from "@sections/story/story-info.vue";
import { useGlobalStore } from "@stores/global";

import Loading from "@components/global/loading.vue";

const global = useGlobalStore();
</script>

<template>
	<Loading v-if="!global.characters?.length || !global.storyUpdates?.length" />
	<template v-else>
		<StoryChars />
		<StoryInfo />
	</template>
</template>
