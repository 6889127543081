<script setup>
import { ref } from "vue";

import Button from "@components/global/button.vue";
import TransitionExpand from "@components/global/transition-expand.vue";

defineProps(["supporter", "index"]);

const opened = ref(false);
</script>

<template>
	<div class="flex w-full items-start gap-2 rounded-max bg-border-default p-1">
		<img :src="supporter.user.avatar" alt="profile image" class="aspect-square w-full max-w-[25%] rounded-max object-contain" />
		<div class="flex w-full flex-col">
			<div class="w-full rounded-max bg-bg p-1">
				<div class="relative flex w-full flex-col gap-1 rounded-max border border-border-default bg-neutral-700 p-4 text-default">
					<router-link :to="{ name: 'profile', params: { username: supporter.user.username } }">
						<i class="icon-arrow-up-right absolute right-4 top-4 text-[20px] text-default" />
					</router-link>

					<span class="label-medium">
						{{ supporter.user.name }}
					</span>
					<span class="label-medium opacity-50">
						{{ supporter.user.username }}
					</span>
					<!-- <div class="mt-3 flex gap-3">
						<a v-for="social in supporter.socials" :key="social.link" :href="social.link">
							<i :class="social.platform.icon" />
						</a>
					</div> -->
				</div>
			</div>
			<TransitionExpand>
				<div class="mt-3 h-auto w-full rounded-max bg-bg p-1" v-if="opened">
					<div class="relative flex w-full flex-col gap-3 rounded-max border border-border-default bg-neutral-700 p-4 text-default">
						<div class="flex items-center gap-1" v-if="supporter.bounty">
							<i class="fa-solid fa-sack text-default opacity-50" />
							<span class="label-medium opacity-50">bounty</span>
							<span class="label-medium">{{ supporter.bounty }}</span>
						</div>
						<div class="h-[1px] w-full bg-border-default" v-if="supporter.description" />
						<p class="md opacity-70" v-if="supporter.description">{{ supporter.description }}</p>
					</div>
				</div>
			</TransitionExpand>
			<div class="relative flex flex-1 items-center gap-2 px-3 py-2">
				<img class="h-10 w-10" src="https://cdn.ether.site/images/bounty-page/bounty-logo.png" alt="Bounty Logo" />
				<span class="label-medium colored colored-text"> Bounty #{{ index + 1 }} </span>
				<div class="flex-1" />
				<Button without-edge="true" class="plus" @click="opened = !opened">
					<i :class="['fa fa-plus text-default', !opened ? 'rotate-0' : 'rotate-45']" />
				</Button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.btn.plus {
	@apply h-8 w-8 bg-neutral-500;
}
</style>
