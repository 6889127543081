<template>
	<div class="modal-overlay" @click="global.closeModal()" v-if="global.modal.name === 'size'">
		<div class="mx-auto overflow-x-auto">
			<div class="flex min-w-max flex-col rounded-big bg-neutral-700 uppercase text-default" @click="$event.stopPropagation()">
				<div class="flex items-center justify-between border-b border-border-default p-4">
					<span class="font-heading text-2xl">size guide</span>
					<Button class="close" without-edge="true" @click="global.closeModal()">
						<i class="fa fa-xmark" />
					</Button>
				</div>
				<div class="grid grid-cols-5 gap-6 p-4">
					<div class="col-span-5 rounded-big bg-neutral-700">
						<div class="grid grid-cols-4 bg-border-default">
							<div v-for="label of data.labels" class="col border-t border-neutral-500">
								<p class="md">{{ label }}</p>
							</div>
						</div>
						<div v-for="row of data.sizes" class="grid grid-cols-4">
							<div v-for="value of Object.values(row)" class="col border-b border-border-default">
								<p class="md">{{ value }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
import { useGlobalStore } from "@stores/global";
import { computed } from "vue";

import Button from "@components/global/button.vue";

const global = useGlobalStore();
const data = computed(() => JSON.parse(global.modal?.payload) ?? null);
</script>

<style scoped>
.col {
	@apply flex items-center justify-center break-all border-r px-6 py-4 first:border-l;
}
</style>
