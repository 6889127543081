<script setup>
import DelimiterSection from "@sections/delimiter-crosses.vue";
import FashionSection from "@sections/home/fashion.vue";
import HeroSection from "@sections/home/hero.vue";
import JoinSection from "@sections/home/join.vue";
import StorytellingSection from "@sections/home/storytelling.vue";
import WhoWeAreSection from "@sections/home/who-we-are.vue";
import { useGlobalStore } from "@stores/global.js";

import Loading from "@components/global/loading.vue";

const global = useGlobalStore();
</script>

<template>
	<Loading v-if="!global.heroShowcase" />
	<template v-else>
		<HeroSection />
		<WhoWeAreSection />
		<DelimiterSection class="delimiter-crosses flex flex-wrap items-center justify-center" :rows="3" />
		<FashionSection />
		<StorytellingSection />
		<JoinSection />
	</template>
</template>

<style scoped>
.delimiter-crosses {
	@apply w-full px-0;
}
</style>
