import _ from "lodash";
import { defineStore } from "pinia";

import router from "../router";
import { useGlobalStore } from "./global";

export const useAuthStore = defineStore("auth", {
	state: () => {
		return {
			user: null,
		};
	},
	getters: {
		isAuthed: (state) => state.user && !_.isEmpty(state.user),
		isAuthenticating: (state) => (!state.user ? true : false),
	},
	actions: {
		setup() {
			this.auth();
		},
		auth() {
			return axios
				.get("/api/auth")
				.then((user) => {
					this.user = user.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		checkAuth() {
			return new Promise((resolve, reject) => {
				axios
					.get("/api/auth")
					.then((user) => {
						this.user = user.data;
						resolve();
					})
					.catch((error) => {
						console.error(error);
						reject(error.response.data.errors);
					});
			});
		},
		login(data) {
			const global = useGlobalStore();
			return new Promise((resolve, reject) => {
				axios
					.post("/login", data)
					.then((a) => {
						this.auth().then(() => {
							global.closeModal();
							router.replace({ name: "profile", params: { username: data.username } });
						});
					})
					.catch((error) => {
						if (error.response.status === 422) {
							reject(error.response.data.errors);
						}
					});
			});
		},
		register(data) {
			const global = useGlobalStore();
			return new Promise((resolve, reject) => {
				axios
					.post("/register", data)
					.then(() => {
						this.auth().then(() => {
							global.closeModal();
							router.replace({ name: "profile", params: { username: data.username } });
						});
					})
					.catch((error) => {
						if (error.response.status === 422) {
							reject(error.response.data.errors);
						}
					});
			});
		},
		updateProfile(data) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/profile", data)
					.then(() => {
						this.auth().then(() => {
							resolve();
						});
					})
					.catch((error) => {
						if (error.response.status === 422) {
							reject(error.response.data.errors);
						}
					});
			});
		},
		updateAccount(data) {
			console.log(data);
			return new Promise((resolve, reject) => {
				axios
					.post("/api/account", data)
					.then(() => {
						this.auth().then(() => {
							resolve();
						});
					})
					.catch((error) => {
						if (error.response.status === 422) {
							reject(error.response.data.errors);
						}
					});
			});
		},
	},
});
