<script setup>
import { useGlobalStore } from "@stores/global";

import Button from "@components/global/button.vue";

const global = useGlobalStore();
</script>

<template>
	<section class="container relative pt-4 lg:pt-8" v-if="global.heroShowcase">
		<div class="relative">
			<img class="max-h-[650px] min-h-[270px] w-full rounded-big border border-bg object-cover" :src="global.heroShowcase.background" alt="Background Image" />
			<!-- alternative for video -->
			<!--      <video-->
			<!-- class="max-h-[650px] w-full rounded-big border border-bg object-cover" -->
			<!--        src=""-->
			<!--        autoplay-->
			<!--        loop-->
			<!--        muted-->
			<div class="absolute -bottom-[2.5rem] right-[800px] hidden h-[105px] w-full xl:block">
				<div class="relative h-full overflow-hidden">
					<div class="absolute bottom-[70%] right-[40px] z-images h-[1px] w-full origin-top-left bg-border-default" />
					<div class="vector right-0">
						<svg width="1158" height="105" viewBox="0 0 1158 105" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M1093.01 7.02824L1150.02 64.0282C1165.14 79.1469 1154.44 105 1133.05 105H24C10.7451 105 0 94.2548 0 81V24C0 10.7452 10.7452 0 24 0H1076.05C1082.41 0 1088.51 2.5281 1093.01 7.02824Z" fill="#171717" />
						</svg>
					</div>
				</div>
			</div>

			<div class="absolute -top-[115px] left-[800px] hidden h-[175px] w-[800px] xl:block">
				<div class="relative h-full overflow-hidden">
					<div class="absolute -left-[100%] bottom-0 z-lines h-[1px] w-[1000px] origin-bottom-right rotate-45 bg-border-default" />
					<div class="vector left-0">
						<svg width="1951" height="175" viewBox="0 0 1951 175" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M134.906 167.967L7.95257 40.9675C-7.16235 25.8471 3.5466 0 24.9262 0H1927C1940.25 0 1951 10.7452 1951 24V151C1951 164.255 1940.25 175 1927 175H151.88C145.513 175 139.407 172.47 134.906 167.967Z" fill="#171717" />
						</svg>
					</div>
				</div>
			</div>

			<div class="box box-left" />
			<div class="box box-right" />
			<div class="box box-mid-bottom" />
			<div class="box box-mid-top" />
		</div>

		<div class="content">
			<div class="flex flex-col gap-2 lg:gap-6">
				<span class="label-medium text-red-base" v-if="global.heroShowcase.subtitle"> /// {{ global.heroShowcase.subtitle }}</span>
				<h1 class="flex flex-col uppercase text-default" v-if="global.heroShowcase.title">
					<span>{{ global.heroShowcase.title }}</span>
				</h1>
			</div>
			<Button class="default" :href="global.heroShowcase.cta_link" icon="fa-solid fa-arrow-up-right sm:text-base text-[12px] leading-4" v-if="global.heroShowcase.cta_label && global.heroShowcase.cta_link" target="_self">{{ global.heroShowcase.cta_label }}</Button>
		</div>
	</section>
</template>

<style scoped>
.btn.default {
	@apply px-3 py-2 sm:px-4 sm:py-2.5;
}

.vector {
	@apply absolute hidden bg-no-repeat fill-bg xl:flex;
}

.vector-left {
	@apply -bottom-[2.5rem] right-[800px] z-images;
}

.vector-top {
	@apply -top-[115px] left-[800px] z-images;
}

.label-medium {
	@apply text-[12px] sm:text-sm;
}

.box {
	@apply absolute hidden h-8 w-8 xl:block;
}

.box-mid-bottom {
	@apply bottom-0 right-[788px];
	border-bottom-left-radius: 60px 30px;
	box-shadow: 0 20px 0 0 #171717;
}

.box-mid-top {
	@apply left-[870px] top-0;
	border-top-right-radius: 40px 40px;
	box-shadow: 0 -20px 0 0 #171717;
}

.box-left {
	@apply bottom-[65px] left-0 rounded-bl-big;
	box-shadow: 0 20px 0 0 #171717;
}

.box-right {
	@apply right-0 top-[60px] rounded-tr-big;
	box-shadow: 0 -20px 0 0 #171717;
}

.content {
	@apply absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-2 pl-32 lg:pl-96;
}
</style>
