import _ from "lodash";
import { defineStore } from "pinia";
import { useRoute } from "vue-router";

import { useGlobalStore } from "./global";

const route = useRoute();

export const useStoreStore = defineStore("store", {
	state: () => {
		return {
			cart: {},
			cartLoading: false,
			product: null,
			collections: [],
			featured: [],
			showcases: [],
			filter: null,
		};
	},
	getters: {
		collection: (state) => {
			return (slug) =>
				state.collections.filter(function (c) {
					return c.slug == slug;
				})[0] ?? null;
		},
		featuredCollection: (state) => {
			return (
				state.collections.filter(function (c) {
					return c.featured;
				})[0] ?? {}
			);
		},
	},
	actions: {
		setup() {
			this.getCart();
			this.getCollections();
		},
		//
		setFilter(slug) {
			this.filter = slug != this.filter ? slug : null;
		},
		getCollections() {
			return axios
				.get("/api/store/collections")
				.then((collections) => {
					this.collections = collections.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		getProduct(slug) {
			return axios
				.get(`/api/store/product/${slug}`)
				.then((product) => {
					this.product = product.data;
				})
				.catch((error) => {
					console.error(error);
				});
		},
		//
		getCart() {
			//   if(cookies.isKey('shopify')) {
			this.cartLoading = true;
			axios
				.get("/api/store/cart")
				.then((cart) => {
					this.cartLoading = false;
					this.cart = cart.data;
				})
				.catch((error) => {
					throw error;
				});
			//   }
		},
		addToCart(variant) {
			const global = useGlobalStore();
			global.cart = true;
			this.cartLoading = true;
			return axios
				.post("/api/store/cart", {
					variantId: variant.id,
					quantity: variant.quantity ?? 1,
				})
				.then((cart) => {
					this.cart = cart.data;
					this.cartLoading = false;
				})
				.catch((error) => {
					throw error;
				});
		},
		updateCart(cartItem) {
			this.cartLoading = true;
			return axios
				.post("/api/store/cart/update", {
					id: cartItem.id,
					quantity: cartItem.quantity ?? 1,
				})
				.then((cart) => {
					this.cart = cart.data;
					this.cartLoading = false;
					return cart.data;
				})
				.catch((error) => {
					throw error;
				});
		},
	},
});
