<script setup>
import { useGlobalStore } from "@stores/global";

import Button from "@components/global/button.vue";

const global = useGlobalStore();
</script>

<template>
	<section class="container flex flex-col items-center gap-4 text-default">
		<span class="label-small rounded-[8px] border border-border-default bg-neutral-700 px-2 py-1"> news </span>
		<h1 class="relative z-[3] uppercase">
			<div class="flex justify-center">
				<span class="font-heading-semibold">ev</span>
				<span class="font-heading-bold">erything you nee</span>
				<span class="font-heading-semibold">d</span>
			</div>
			<div class="flex justify-center">
				<span class="font-heading-semibold">to kno</span>
				<span>w about The</span>
				<span class="ml-3 font-heading-semibold">ether</span>
			</div>
		</h1>
		<p class="z-[3]">Explore the recent news about the ETHER.</p>

		<Button :to="{ name: 'article', params: { slug: global.article.slug } }" :without-edge="true" class="w-full">
			<div class="relative w-full">
				<div class="w-full overflow-hidden">
					<img class="animation max-h-[650px] min-h-[270px] w-full rounded-big border border-bg object-cover hover:scale-[1.05]" :src="global.article.image_path" alt="" />
				</div>

				<div class="absolute -bottom-[2.5rem] right-[800px] hidden h-[105px] w-full xl:block">
					<div class="relative h-full overflow-hidden">
						<div class="absolute bottom-[70%] right-[40px] z-images h-[1px] w-full origin-top-left bg-border-default" />
						<div class="vector right-0">
							<svg width="1158" height="105" viewBox="0 0 1158 105" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1093.01 7.02824L1150.02 64.0282C1165.14 79.1469 1154.44 105 1133.05 105H24C10.7451 105 0 94.2548 0 81V24C0 10.7452 10.7452 0 24 0H1076.05C1082.41 0 1088.51 2.5281 1093.01 7.02824Z" fill="#171717" />
							</svg>
						</div>
					</div>
				</div>

				<div class="absolute -top-[115px] left-[800px] hidden h-[175px] w-[800px] xl:block">
					<div class="relative h-full overflow-hidden">
						<div class="absolute -left-[100%] bottom-0 z-lines h-[1px] w-[1000px] origin-bottom-right rotate-45 bg-border-default" />
						<div class="vector left-0">
							<svg width="1951" height="175" viewBox="0 0 1951 175" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M134.906 167.967L7.95257 40.9675C-7.16235 25.8471 3.5466 0 24.9262 0H1927C1940.25 0 1951 10.7452 1951 24V151C1951 164.255 1940.25 175 1927 175H151.88C145.513 175 139.407 172.47 134.906 167.967Z" fill="#171717" />
							</svg>
						</div>
					</div>
				</div>

				<div class="box box-left" />
				<div class="box box-right" />
				<div class="box box-mid-bottom" />
				<div class="box box-mid-top" />

				<div class="top-[50%] flex flex-col gap-4 p-4 text-default lg:absolute lg:left-[10%] lg:w-half xl:top-[60%]">
					<div class="flex gap-2">
						<span class="label-small outlined">{{
							new Date(global.article.published_at).toLocaleDateString("en-US", {
								month: "long",
								day: "numeric",
								year: "numeric",
							})
						}}</span>
						<span class="label-small inverse">partnerships</span>
					</div>
					<h4 class="uppercase">
						{{ global.article.title }}
					</h4>
				</div>
			</div>
		</Button>
	</section>
	<div class="relative flex h-full w-full flex-col gap-8"></div>
</template>

<style scoped>
.vector {
	@apply absolute hidden bg-no-repeat fill-bg lg:flex;
}

.vector-left {
	@apply bottom-[-1px] left-0 z-images h-[60px] w-[500px];
}

.vector-top {
	@apply right-0 top-[-2px] z-images h-[50px] w-[400px];
}

h1 {
	@apply text-[11vw] sm:text-[7vw] lg:text-[5rem];
}

.btn {
	@apply flex-col p-0 lg:flex-row;
}

.box {
	@apply absolute hidden h-8 w-8 xl:block;
}

.box-mid-bottom {
	@apply bottom-0 right-[788px];
	border-bottom-left-radius: 60px 30px;
	box-shadow: 0 20px 0 0 #171717;
}

.box-mid-top {
	@apply left-[870px] top-0;
	border-top-right-radius: 40px 40px;
	box-shadow: 0 -20px 0 0 #171717;
}

.box-left {
	@apply bottom-[65px] left-0 rounded-bl-big;
	box-shadow: 0 20px 0 0 #171717;
}

.box-right {
	@apply right-0 top-[60px] rounded-tr-big;
	box-shadow: 0 -20px 0 0 #171717;
}
</style>
