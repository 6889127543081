<script setup>
import { useGlobalStore } from "@stores/global.js";

import Cart from "@components/cart/Cart.vue";
import Footer from "@components/global/footer.vue";
import Header from "@components/global/header.vue";

const global = useGlobalStore();
</script>

<template>
	<Transition name="cart" :duration="550">
		<Cart v-if="global.cart" />
	</Transition>
	<Header />
	<main>
		<router-view v-slot="{ Component }">
			<component :is="Component" />
		</router-view>
	</main>
	<Footer />
</template>
