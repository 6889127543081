<script setup>
import DelimiterSection from "@sections/delimiter-crosses.vue";
import { useGlobalStore } from "@stores/global.js";
import Markdown from "vue3-markdown-it";
import { useRouter } from "vue-router";

import Button from "@components/global/button.vue";

const router = useRouter();
const global = useGlobalStore();
</script>
<template>
	<Button @click="router.back()" class="btn outlined z-header text-default lg:mt-[-6rem]" :without-edge="true"> <i class="icon-arrow-left" />Back </Button>
	<div class="relative">
		<img class="max-h-[700px] w-full object-cover" :src="global.article.image_path" alt="" />
		<div class="vector vector-left">
			<img src="https://cdn.ether.site/images/home-page/bot-left.svg" class="w-full" alt="" />
			<div class="absolute left-[-50%] top-[50%] h-[1px] w-[655px] origin-top-left bg-border-default" />
		</div>
	</div>
	<section class="container flex flex-col items-center gap-4 text-default lg:gap-12">
		<div class="flex flex-col gap-4 lg:w-[80%]">
			<div class="flex gap-2">
				<span class="label-small outlined w-fit">
					{{
						new Date(global.article.published_at).toLocaleDateString("en-US", {
							month: "long",
							day: "numeric",
							year: "numeric",
						})
					}}
				</span>
				<span class="label-small inverse">partnerships</span>
			</div>
			<h4 class="animation uppercase">
				{{ global.article.title }}
			</h4>
		</div>

		<div class="h-[1px] w-full bg-border-default lg:w-[80%]" />

		<Markdown class="markdown article" :source="global.article.body" :breaks="true" />

		<DelimiterSection :rows="4" />
	</section>
</template>

<style scoped>
.vector {
	@apply absolute hidden bg-no-repeat fill-bg lg:flex;
}

.vector-left {
	@apply bottom-0 left-0 z-images h-[60px] w-[500px];
}

.btn.outlined {
	@apply sticky left-12 top-[20%] hidden bg-bg bg-opacity-90 lg:flex;
}
</style>
