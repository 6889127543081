<template>
	<transition name="expand" @enter="enter" @after-enter="afterEnter" @leave="leave">
		<slot />
	</transition>
</template>

<script>
export default {
	name: "TransitionExpand",
	methods: {
		enter(element) {
			const width = getComputedStyle(element).width;

			element.style.width = width;
			element.style.position = "absolute";
			element.style.visibility = "hidden";
			element.style.height = "auto";

			const height = getComputedStyle(element).height;

			element.style.width = null;
			element.style.position = null;
			element.style.visibility = null;
			element.style.height = 0;

			getComputedStyle(element).height;
			requestAnimationFrame(() => {
				element.style.height = height;
			});
		},
		afterEnter(element) {
			element.style.height = "auto";
		},
		leave(element) {
			const height = getComputedStyle(element).height;

			element.style.height = height;

			getComputedStyle(element).height;

			requestAnimationFrame(() => {
				element.style.height = 0;
			});
		},
	},
};
</script>

<style scoped>
* {
	will-change: height, opacity;
	transform: translateZ(0);
	backface-visibility: hidden;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-ms-perspective: 1000;
	perspective: 1000;
}
</style>

<style>
.expand-enter-active,
.expand-leave-active {
	transition: height 0.3s ease;
	overflow: hidden;
}
.expand-enter,
.expand-leave-to {
	height: 0;
}
</style>
