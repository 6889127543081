<script setup>
import { useGlobalStore } from "@/stores/global";
import { format } from "@formkit/tempo";
import DelimiterCrosses from "@sections/delimiter-crosses.vue";
import axios from "axios";
import { computed, ref, watch } from "vue";
import Markdown from "vue3-markdown-it";
import { useRoute, useRouter } from "vue-router";

import Loading from "@components/global/Loading.vue";

const global = useGlobalStore();
const route = useRoute();
const loading = ref(false);
const page = ref({});
const requestPage = ref(null);
const slug = computed(() => route.params.slug);

function cancelPage() {
	if (requestPage.value) requestPage.value.cancel();
}
function getPage() {
	loading.value = true;
	page.value = {};
	cancelPage();
	let axiosSource = axios.CancelToken.source();
	requestPage.value = { cancel: axiosSource.cancel };
	axios
		.get(`/api/page/${route.params.slug}`, {
			cancelToken: axiosSource.token,
		})
		.then((p) => {
			loading.value = false;
			page.value = p.data;
		})
		.catch((e) => {});
}
getPage();

watch(slug, async () => {
	getPage();
});
</script>

<template>
	<Loading v-if="!page.title" />
	<section class="container" v-else>
		<div class="relative rounded-max border border-border-default p-4 text-default lg:p-20">
			<div class="page-border"></div>
			<div class="mb-2 font-heading text-4xl">{{ page.title }}</div>
			<div class="label-medium">last updated: {{ format(page.updated_at, "long") }}</div>
			<DelimiterCrosses class="delimiter-crosses" :rows="3" />
			<Markdown class="markdown" :source="page.body" :breaks="true" />
		</div>
	</section>
</template>

<style scoped>
.delimiter-crosses {
	@apply px-0;
}

.page-border {
	@apply absolute right-4 top-4 block h-[3.5rem] w-[3.5rem] rounded-tr-[16px] border-r border-t border-r-default border-t-default;
}

.page-border::after {
	position: absolute;
	top: 16px;
	right: 16px;
	content: "";
	display: block;
	width: 3px;
	height: 3px;
	background: #9e76b2;
}
</style>
