<script setup lang="ts">
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { ref } from "vue";

import Button from "@components/global/button.vue";
import Checkbox from "@components/global/checkbox.vue";
import Input from "@components/global/input.vue";

const global = useGlobalStore();
const auth = useAuthStore();
const processing = ref(false);
const user = ref({});
const errors = ref({});

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		auth.register(user.value).catch((errorBag) => {
			processing.value = false;
			errors.value = errorBag;
		});
	}
}
</script>

<template>
	<div class="modal-overlay" v-if="global.modal?.name === 'sign-up'" @click="global.closeModal()">
		<div class="modal-content max-w-[50rem]" @click="$event.stopPropagation()">
			<div class="flex items-center justify-between p-4 uppercase">
				<i class="icon-logo text-4xl" />
				<span class="label-medium">sign up</span>
				<Button class="close" without-edge="true" @click="global.closeModal()">
					<i class="fa fa-xmark" />
				</Button>
			</div>
			<form class="flex w-full flex-col gap-6 rounded-max border border-border-default bg-neutral-700 p-4" action="" @submit="submit">
				<Input label="username" placeholder="Insert your username" filter="username" max="20" :required="true" v-model="user.username" :error="errors.username && errors.username[0]" />
				<Input label="email" placeholder="Insert your email" type="email" max="128" :required="true" v-model="user.email" :error="errors.email && errors.email[0]" />
				<div class="flex flex-col gap-3 lg:flex-row">
					<Input label="password" type="password" placeholder="Insert your password" :required="true" v-model="user.password" :error="errors.password && errors.password[0]" />
					<Input label="password" type="password" placeholder="Insert your password one more time" :required="true" v-model="user.password_confirmation" :error="errors.password_confirmation && errors.password_confirmation[0]" />
				</div>
				<Checkbox label="I confirm I am 13 or older*" v-model="user.age_consent" :error="errors.age_consent && errors.age_consent[0]" />
				<Button class="default" :processing="processing">Sign up!</Button>
				<span class="label-medium self-center">
					Already have an account?
					<span class="cursor-pointer text-purple-base" @click="global.openModal(['login'])"> Log in </span>
				</span>
				<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
				<Button type="button" without-edge="true" class="btn-view w-fit self-center" :to="{ name: 'page', params: { slug: 'privacy' } }" @click="global.closeModal()">view privacy policy</Button>
			</form>
		</div>
	</div>
</template>

<style scoped>
.btn-view {
	@apply bg-neutral-700;
}
</style>
