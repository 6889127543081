<template>
	<section class="container flex flex-col items-center gap-[60px] text-default lg:mb-[200px]">
		<div class="relative h-[200px] w-[1px] bg-border-default">
			<i class="icon-star-join absolute left-0 top-[50%] translate-x-[-50%] translate-y-[-50%] bg-bg py-6 text-2xl"></i>
		</div>
		<!-- <h4 class="inline flex-col items-center text-center uppercase lg:flex">
			ETHER is a brand shaped by the people,
      <span>for the people.</span>
		</h4> -->
		<i class="icon-logo text-[72px]" />
	</section>
</template>
