<script setup>
import { useStoreStore } from "@stores/store";
import { computed } from "vue";

import Button from "@components/global/button.vue";

const store = useStoreStore();
const props = defineProps(["product"]);

const plusButtonDisabled = computed(() => props.product.quantity >= 10);
const minusButtonDisabled = computed(() => props.product.quantity <= 1);

function changeQuantity(delta) {
  let quantity = props.product.quantity + delta;
  if (quantity > 0 && quantity <= 10) {
    store.updateCart({
      id: props.product.id,
      quantity: quantity,
    });
  }
}
function remove() {
  store.updateCart({
    id: props.product.id,
    quantity: 0,
  });
}
</script>

<template>
  <div class="flex items-center gap-6 text-sm">
    <div class="h-28 w-28 rounded-big bg-border-default p-4">
      <img
        class="h-full w-full object-contain"
        :src="product.image"
        :alt="product.title" />
    </div>
    <div class="flex grow flex-col gap-3">
      <div class="flex gap-2">
        <div class="flex flex-col gap-1">
          <div class="flex flex-col">
            <p class="md">{{ product.title }}</p>
            <p class="md text-purple-base">{{ product.price }}</p>
          </div>
          <div
            class="flex items-center gap-2 text-neutral-500"
            v-if="product.variantTitle">
            <p>{{ product.variantTitle }}</p>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex w-fit items-stretch rounded-[8px] bg-border-default">
          <Button
            :without-edge="true"
            class="flex h-8 w-8 items-center justify-center transition-colors duration-500 hover:bg-neutral-500 disabled:hover:bg-border-default"
            :disabled="minusButtonDisabled"
            @click="changeQuantity(-1)">
            <i class="fa-solid fa-minus"></i>
          </Button>
          <div class="flex items-center px-5">
            <span>{{ product.quantity }}</span>
          </div>
          <Button
            :without-edge="true"
            class="flex h-8 w-8 items-center justify-center transition-colors duration-500 hover:bg-neutral-500 disabled:hover:bg-border-default"
            :disabled="plusButtonDisabled"
            @click="changeQuantity(+1)">
            <i class="fa-solid fa-plus"></i>
          </Button>
        </div>
        <Button class="remove" :without-edge="true" @click="remove">
          <i class="fa-regular fa-trash-can text-red-base"></i>
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.btn.remove {
  @apply bg-border-default px-3 py-[9px];
}
</style>
