<script setup>
import { useGlobalStore } from "@stores/global";
import { useStoreStore } from "@stores/store";

import CartItem from "@components/cart/CartItem.vue";
import Button from "@components/global/button.vue";

const global = useGlobalStore();
const store = useStoreStore();

const checkout = () => {
	window.location.href = store.cart?.checkoutURL;
};
</script>

<template>
	<div class="fixed right-0 top-0 isolate z-[1000] flex h-full w-full select-none justify-end">
		<div class="-z-10 overlay fixed left-0 top-0 hidden h-full w-full bg-black/75 md:block" @click="global.toggleCart(false)" />
		<div class="z-10 inner relative flex h-full w-full flex-col bg-bg text-default md:max-w-md 2xl:max-w-xl">
			<div class="flex items-center justify-between border-b border-border-default p-2 md:p-4">
				<div>
					<h4 class="text-lg uppercase md:text-2xl">Your Cart</h4>
					<p class="md opacity-70">Review your order and proceed to checkout.</p>
				</div>
				<Button without-edge="true" class="flex select-none items-center justify-center" @click="global.toggleCart(false)">
					<i class="fa fa-xmark" />
				</Button>
			</div>
			<div class="relative h-full flex-1 overflow-y-auto">
				<Transition name="fade">
					<div class="absolute flex h-full w-full items-center justify-center gap-4 bg-bg" v-if="store.cartLoading">
						<i class="fa-solid fa-spinner-third animate-spin text-lg" />
						<p class="animate-pulse text-sm font-bold uppercase tracking-widest">Processing</p>
					</div>
				</Transition>
				<div class="my-10 flex grow items-center justify-center gap-4" v-if="!store.cart.products?.length">
					<p class="text-sm font-bold uppercase tracking-widest">Your cart is empty</p>
				</div>
				<div class="flex flex-1 grow flex-col gap-4 overflow-y-auto p-4" v-else>
					<CartItem v-for="product in store.cart.products" :key="product.slug" :product="product" />
				</div>
			</div>
			<div class="2xl:text-md flex flex-col gap-2 p-4">
				<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
				<div class="flex items-center justify-between">
					<p>Subtotal</p>
					<i class="fa-solid fa-spinner-third animate-spin" v-if="store.cartLoading"></i>
					<p v-else>{{ store.cart.cost?.subtotal ?? "$0.00" }}</p>
				</div>
				<div class="flex items-center justify-between">
					<p>Delivery</p>
					<p>Calculated at checkout</p>
				</div>
				<div class="flex items-center justify-between border-t border-border-default">
					<p>Total</p>
					<i class="fa-solid fa-spinner-third animate-spin" v-if="store.cartLoading"></i>
					<p class="text-white" v-else>
						{{ store.cart.cost?.subtotal ?? "$0.00" }}
					</p>
				</div>
				<p class="mb-1 text-sm opacity-40">We are currently unable to fulfill orders to California, USA.</p>
				<Button :icon="store.cartLoading ? 'fa-solid fa-spinner-third animate-spin' : 'fa-solid fa-arrow-right'" :without-edge="true" :disabled="store.cartLoading || !store.cart.products?.length" @click="checkout">
					{{ store.cartLoading ? "Please Wait" : "Go to Checkout" }}
				</Button>
			</div>
		</div>
	</div>
</template>

<style scoped>
.btn {
	@apply bg-border-default p-3;
}
</style>
