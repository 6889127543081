<script setup>
import Address from "@sections/profile/edit/address.vue";
import MyProfile from "@sections/profile/edit/my-profile.vue";
import Orders from "@sections/profile/edit/orders.vue";
import Personal from "@sections/profile/edit/personal.vue";
import { useAuthStore } from "@stores/auth";
import { ref } from "vue";

import Loading from "@components/global/loading.vue";

const auth = useAuthStore();
const tab = ref("profile");

const handle = (value) => {
	tab.value = value;
};
</script>

<template>
	<Loading v-if="!auth.user?.username" />
	<section class="container flex flex-col gap-12 lg:flex-row" v-else>
		<div class="flex h-full w-full min-w-[300px] flex-col gap-5 lg:w-[20%]">
			<!-- <div class="label-medium rounded-max border border-border-default p-1 text-default">
				<div class="px-4 py-3">Crypto</div>
				<div class="flex flex-col gap-4 rounded-max bg-neutral-700 p-4">
					<div class="menu-bar-item"><i class="fa-solid fa-wallet" />Connect Wallet</div>
				</div>
			</div> -->
			<div class="label-medium rounded-max border border-border-default p-1 text-default">
				<div class="px-4 py-3">My Account</div>
				<div class="flex flex-col gap-4 rounded-max border border-border-default bg-neutral-700 p-4">
					<div class="menu-bar-item" @click="handle('profile')"><i class="fa-solid fa-id-card" />Edit Profile</div>
					<a class="cursor-pointer text-red-base" href="/logout">Log Out</a>
				</div>
			</div>
		</div>

		<div v-if="tab === 'profile'" class="flex w-full flex-col gap-10">
			<MyProfile />
			<Personal />
			<!-- <Address /> -->
		</div>
		<div v-else class="flex-1">
			<Orders />
		</div>
	</section>
</template>

<style scoped>
.menu-bar-item {
	@apply flex cursor-pointer items-center gap-2 border-b border-border-default pb-4 text-sm;
}
.menu-bar-item:last-child {
	@apply border-none pb-0;
}
</style>
