<script setup>
import CollectionBanner from "@sections/shop-list/collection-banner.vue";
import CollectionItemsRow from "@sections/shop-list/collection-items-row.vue";
import CollectionTypes from "@sections/shop-list/collection-types.vue";
import { useStoreStore } from "@stores/store";

import Countdown from "@components/global/countdown.vue";
import Loading from "@components/global/loading.vue";

const store = useStoreStore();
</script>

<template>
	<Loading v-if="!store.collection($route.params.id)?.title" />
	<Countdown v-else-if="store.collection($route.params.id)?.launch_at" :date="store.collection($route.params.id)?.launch_at" />
	<template v-else>
		<CollectionTypes />
		<CollectionBanner />
		<CollectionItemsRow />
	</template>
</template>
