import * as Sentry from "@sentry/vue";
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { useProfileStore } from "@stores/profile.js";
import { useStoreStore } from "@stores/store";
import { createPinia } from "pinia";
import "swiper/css";
import "swiper/css/navigation";
import { createApp } from "vue/dist/vue.esm-bundler";

import "./bootstrap";
import router from "./router";

const pinia = createPinia();
const app = createApp({}).use(router).use(pinia);
const global = useGlobalStore();
const auth = useAuthStore();
const store = useStoreStore();
const profile = useProfileStore();

if (import.meta.env.VITE_APP_ENV != "local") {
	Sentry.init({
		app,
		dsn: "https://a6ce13b47d670769df4a07007d17b8b9@o4508088667144192.ingest.us.sentry.io/4508337659904000",
		integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
		tracesSampleRate: 1.0,
		tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: import.meta.env.VITE_APP_ENV,
	});
}

global.setup();
auth.setup();
store.setup();
profile.setup();
app.mount("#ether");
