<script setup>
import { useStoreStore } from "@stores/store";
import { useRouter } from "vue-router";

import Button from "@components/global/button.vue";

const store = useStoreStore();
const router = useRouter();
</script>

<template>
	<section class="container">
		<div class="relative h-full w-full">
			<div class="h-52 w-full rounded-big bg-neutral-700">
				<div class="relative h-full w-full">
					<img class="absolute right-[20%] hidden h-full xl:block" :src="store.collection($route.params.id)?.headerImage ?? 'https://cdn.ether.site/images/elements/characters.png'" alt="ETHER Chracters" />
					<img class="absolute left-[-20%] top-[-200%] hidden lg:block" src="https://cdn.ether.site/images/shop-page/collection-shop-vector.svg" alt="image" />
					<img class="absolute right-[-20%] top-[-200%]" src="https://cdn.ether.site/images/shop-page/collection-shop-vector.svg" alt="image" />
				</div>
			</div>
			<div class="absolute left-6 top-[20%] flex flex-col gap-3 text-default">
				<Button class="btn flex w-fit items-center" without-edge="true" @click="router.back()">
					<i class="fa fa-arrow-left" />
					<span>back</span>
				</Button>
				<span class="font-heading text-4xl uppercase">
					{{ store.collection($route.params.id)?.title }}
				</span>
			</div>
		</div>
	</section>
</template>

<style scoped>
.btn {
	@apply gap-2 px-0;
}
</style>
