<script setup>
import CollectionPreview from "@sections/shop-list/collection-preview.vue";
import CollectionTypes from "@sections/shop-list/collection-types.vue";
import CollectionWithItems from "@sections/shop-list/collection-with-items.vue";
import Hero from "@sections/shop-list/hero.vue";
import { useStoreStore } from "@stores/store";

import Loading from "@components/global/loading.vue";

const store = useStoreStore();
</script>

<template>
	<Loading v-if="!store.collections.length" />
	<template v-else>
		<Hero />
		<CollectionTypes />
		<CollectionWithItems v-for="collection in store.collections" :collection="collection" :cta="{ title: 'shop all', show: true }" />
	</template>
	<!-- <CollectionPreview
    :is-left="true"
    cta-title="see collection"
    :collection="store.collections[0]" /> -->
</template>
