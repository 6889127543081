/* Layouts */
import App from "@layouts/app.vue";
import Error404 from "@pages/404.vue";
import AboutPage from "@pages/about-page.vue";
import Account from "@pages/account.vue";
import ArticlePage from "@pages/article-page.vue";
import BountyPage from "@pages/bounty-page.vue";
import CapsulePage from "@pages/capsules-page.vue";
import Countdown from "@pages/countdown.vue";
import Index from "@pages/index.vue";
import NewsPage from "@pages/news-page.vue";
import PolicyPage from "@pages/policy-page.vue";
import Profile from "@pages/profile.vue";
import ResetPassword from "@pages/reset-password.vue";
import ShopCollectionPage from "@pages/shop-collection-page.vue";
import ShopItemPage from "@pages/shop-item-page.vue";
import ShopListPage from "@pages/shop-list-page.vue";
import StoryPage from "@pages/story-page.vue";
import { createRouter, createWebHistory } from "vue-router";

/* Routes */
const routes = [
	{
		path: "/",
		component: App,
		children: [
			{ path: "", name: "index", component: Index },
			{ path: "shop", name: "shop-list", component: ShopListPage },
			{ path: "product/:slug", name: "product", component: ShopItemPage },
			{ path: "page/:slug", name: "page", component: PolicyPage },
			{ path: "profile/:username", name: "profile", component: Profile },
			{ path: "story", name: "story", component: StoryPage },
			{ path: "about", name: "about", component: AboutPage },
			{ path: "bounty", name: "bounty", component: BountyPage },
			//   { path: "capsules", name: "capsules", component: CapsulePage },
			{ path: "account", name: "account", component: Account },
			{ path: "collection/:id", name: "collection", component: ShopCollectionPage },
			{ path: "news", name: "news", component: NewsPage },
			{ path: "article/:slug", name: "article", component: ArticlePage },
			{ path: "reset-password", name: "reset-password", component: ResetPassword },
			{ path: "coming-soon", name: "countdown", component: Countdown },
			{ path: ":pathMatch(.*)*", name: "404", component: Error404 },
		],
	},
];

const router = createRouter({
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({ left: 0, top: 0 });
			}, 25);
		});
	},
	history: createWebHistory(),
	routes,
});

export default router;
