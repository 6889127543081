<script setup>
import { useGlobalStore } from "@stores/global";
import { ref } from "vue";

const props = defineProps(["modelValue", "filter", "required", "placeholder", "disabled", "label", "error", "items", "sizeGuide"]);

const global = useGlobalStore();

const input = ref(null);

const emit = defineEmits(["update:modelValue"]);
</script>

<template>
	<div class="flex w-full flex-col gap-2 normal-case">
		<div class="flex flex-row justify-between gap-4">
			<label class="label-medium text-sm opacity-70" v-if="label">
				{{ label }}
			</label>
			<span class="label-medium cursor-pointer text-purple-base" @click="global.openModal(['size', sizeGuide])" v-if="label == 'Size' && sizeGuide != null">Size Guide</span>
		</div>

		<div class="relative w-full">
			<i class="text-neutral-600 icon-chevrons-down-up absolute right-3 top-1/2 -translate-y-1/2 pl-3 text-base"></i>
			<select :required="required" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :disabled="disabled">
				<option value="" disabled selected v-if="placeholder">{{ placeholder }}</option>
				<option v-for="item in items" :value="item" :key="item.title">
					{{ item }}
				</option>
				<slot></slot>
			</select>
		</div>
		<p class="text-red-500 text-xs" v-if="error">{{ error }}</p>
	</div>
</template>

<style scoped>
select {
	@apply w-full appearance-none rounded-[12px] border border-border-default bg-neutral-700 p-3 font-worksans normal-case placeholder:normal-case placeholder:opacity-70 focus:outline-none;
}
</style>
