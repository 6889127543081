<script setup>
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import Button from "@components/global/button.vue";
import Input from "@components/global/input.vue";

const router = useRouter();
const route = useRoute();
const global = useGlobalStore();
const auth = useAuthStore();
const processing = ref(false);
const success = ref(false);
const expired = ref(false);
const user = ref({
	email: null,
	token: null,
	password: "",
	password_confirmation: "",
});
const errors = ref({});

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		axios
			.post("/reset-password", user.value)
			.then(() => {
				processing.value = false;
				success.value = true;
			})
			.catch((error) => {
				console.error(error.response.data.errors);
				processing.value = false;
				success.value = false;
				if (error.response.status === 422) {
					let errorBag = error.response?.data?.errors ?? {};
					console.error(errorBag);
					if (errorBag.hasOwnProperty("email")) {
						expired.value = true;
					} else {
						errors.value = errorBag;
					}
				}
			});
	}
}

onMounted(() => {
	user.value.email = route.query?.email;
	user.value.token = route.query?.token;
});
</script>

<template>
	<div class="p-10">
		<div class="flex flex-col items-center justify-center text-white">
			<div class="modal-content max-w-[25rem]">
				<div class="flex items-center justify-between p-4 uppercase">
					<span class="label-medium">Reset Password</span>
				</div>
				<div class="rounded-max border border-border-default bg-neutral-700 p-4 text-sm" v-if="!user.token || !user.email || expired">Reset link expired, please click the link in the email you recieved again, or request a new reset email.</div>
				<div class="rounded-max border border-border-default bg-neutral-700 p-4 text-sm" v-else-if="success">Password reset successfully.</div>
				<form class="flex w-full flex-col gap-6 rounded-max border border-border-default bg-neutral-700 p-4" action="" @submit="submit" v-else>
					<Input label="password" type="password" placeholder="**********" max="255" :required="true" v-model="user.password" :error="errors.password && errors.password[0]" />
					<Input label="confirm password" type="password" placeholder="**********" max="255" :required="true" v-model="user.password_confirmation" :error="errors.password_confirmation && errors.password_confirmation[0]" />
					<Button class="default" :processing="processing">Reset Password</Button>
					<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
				</form>
			</div>
		</div>
	</div>
</template>
