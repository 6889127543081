<script setup>
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { useStoreStore } from "@stores/store";
import { ref } from "vue";

import Button from "@components/global/button.vue";
import Dropdown from "@components/global/dropdown.vue";
import ForgotModal from "@components/modals/forgot-modal.vue";
import LoginModal from "@components/modals/login-modal.vue";
import RegistrationModal from "@components/modals/registration-modal.vue";

const global = useGlobalStore();
const store = useStoreStore();
const auth = useAuthStore();

const links = ref([
	{
		title: "home",
		to: { name: "index" },
	},
	{
		title: "story",
		to: { name: "story" },
	},
	{
		title: "shop",
		to: { name: "shop-list" },
	},
	//   {
	//     title: "news",
	//     to: { name: "news" },
	//   },
	{
		title: "bounty",
		to: { name: "bounty" },
	},
	//   {
	//     title: "capsules",
	//     to: { name: "capsules" },
	//   },
	{
		title: "about",
		to: { name: "about" },
	},
]);

const toggle = (name) => {
	if (global.modal.name === name) {
		global.closeModal();
		return;
	}

	global.openModal([name]);
};
</script>

<template>
	<header>
		<div class="relative z-header hidden w-full lg:flex lg:h-[130px]">
			<div class="fixed left-0 top-0 w-full">
				<div class="header-container pointer-events-none absolute left-0 top-0 h-[200px] w-full"></div>
				<div class="absolute left-0 top-0 w-full px-[48px] pt-[24px]">
					<div class="flex w-full flex-col rounded-big border border-border-default bg-bg text-default">
						<div class="label-small flex justify-between border-b border-b-border-default px-3 py-3 uppercase">
							<div class="flex items-center gap-2" v-if="global.announcement">
								<span class="label-small outlined" v-if="global.announcement?.badge">{{ global.announcement.badge }}</span>
								<span v-if="global.announcement?.name">// {{ global.announcement.name }}</span>
								<a :href="global.announcement?.cta_link" class="label-small inverse" v-if="global.announcement?.cta_label && global.announcement?.cta_link">
									{{ global.announcement.cta_label }}
								</a>
							</div>
							<div class="flex items-center gap-3">
								<span>/////</span>
								<a v-for="social in global.socials" :key="social.avatar_url" :href="social.link" class="header-icon" :title="`${social.platform.name} - ${social.username}`" target="_blank">
									<img class="h-3" src="https://cdn.ether.site/images/icons/opensea.svg" :class="social.platform.icon" v-if="social.platform.slug == 'opensea'" />
									<i :class="social.platform.icon" v-else />
								</a>
							</div>
						</div>
						<div class="flex">
							<div class="border-r border-r-border-default px-3 py-3">
								<router-link :to="{ name: 'index' }">
									<i class="icon-logo text-[40px] hover:opacity-50" />
								</router-link>
							</div>
							<ul class="flex flex-1 items-center justify-center gap-8 px-3 py-3">
								<router-link class="header-link flex items-center gap-2 hover:opacity-50" v-for="(link, i) in links" :key="i" :to="link.to">
									<i class="icon-button-edge hidden text-[6px]" />
									<span class="label-medium">
										{{ link.title }}
									</span>
								</router-link>
								<!-- <Dropdown :items="global.socials" title="socials" /> -->
							</ul>
							<div class="flex items-center border-l border-l-border-default py-3">
								<Button @click="global.toggleCart(true)" class="flex flex-row items-center gap-2" :without-edge="true">
									<i class="icon-shopping-basket text-[24px]" />
									<span class="label-small rounded-small bg-default px-1 text-bg">
										{{ store.cart?.totalQuantity ?? 0 }}
									</span>
								</Button>
								<div class="h-[2rem] w-[1px] bg-border-default" />
								<Button class="default mr-4" @click="global.openModal(['login'])" v-if="!auth.isAuthed"> Login </Button>
								<Button
									withIcon
									:without-edge="true"
									:to="{
										name: 'profile',
										params: { username: auth.user.username },
									}"
									v-else>
									<i class="icon-user text-[20px]" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="relative z-header flex h-[111px] w-full lg:hidden">
			<div class="fixed z-header flex w-[100vw] flex-col justify-center border-b border-border-default bg-bg px-4 text-default lg:hidden">
				<div class="label-small flex justify-between border-b border-b-border-default py-3 uppercase">
					<div class="flex items-center gap-2" v-if="global.announcement">
						<span class="label-small outlined" v-if="global.announcement?.badge">{{ global.announcement.badge }}</span>
						<span v-if="global.announcement?.name">// {{ global.announcement.name }}</span>
						<a :href="global.announcement?.cta_link" class="label-small inverse" v-if="global.announcement?.cta_label && global.announcement?.cta_link">
							{{ global.announcement.cta_label }}
						</a>
					</div>
				</div>
				<div class="flex items-center py-3">
					<router-link :to="{ name: 'index' }">
						<i class="icon-logo text-[40px]" />
					</router-link>
					<div class="flex-1" />
					<div class="flex items-center">
						<Button @click="global.toggleCart(true)" class="flex flex-row items-center gap-2" :without-edge="true">
							<i class="icon-shopping-basket text-[24px]" />
							<span class="label-small rounded-small bg-default px-1 text-bg">
								{{ store.cart?.totalQuantity ?? 0 }}
							</span>
						</Button>
						<div class="h-[2rem] w-[1px] bg-border-default" />
						<Button without-edge="true" @click="toggle('header')">
							<i class="icon-burger text-[24px]" />
						</Button>
					</div>
				</div>
			</div>

			<div :class="[global.modal?.name === 'header' && 'right-[0]', 'fixed right-[-100vw] top-[107px] isolate flex h-[calc(100vh-3.5rem)] w-[70%] flex-col items-center gap-12 overflow-y-auto border-t border-border-default bg-bg px-4 py-[4rem] text-default transition-all duration-[1500ms] lg:hidden']">
				<div class="flex w-full flex-col items-center gap-3">
					<router-link v-for="item in links" :key="item.title" :to="{ name: item.to.name }" @click="global.closeModal()" class="flex w-full justify-center border-b border-border-default py-4 uppercase opacity-50 hover:text-paper-darker">
						<h6 class="flex items-center">
							{{ item.title }}
						</h6>
					</router-link>
				</div>

				<div class="flex flex-wrap items-center justify-center gap-6 text-[20px]">
					<a v-for="social in global.socials" :key="social.avatar_url" :href="social.link" target="_blank">
						<i :class="social.platform.icon"></i>
					</a>
				</div>

				<Button class="default" @click="global.openModal(['login'])" v-if="!auth.isAuthed">
					<span class="label-medium">login</span>
				</Button>

				<Button
					v-else
					class="default"
					withIcon
					:to="{
						name: 'profile',
						params: { username: auth.user.username },
					}">
					<i class="icon-user text-[20px]" />
					<span class="label-medium">my account</span>
				</Button>
			</div>
		</div>
		<LoginModal />
		<RegistrationModal />
		<ForgotModal />
	</header>
</template>

<style scoped>
.header-container {
	background: linear-gradient(180deg, #171717 27.29%, rgba(23, 23, 23, 0) 100%);
	opacity: 0.95;
}

.router-link-exact-active {
	opacity: 100%;
}

.header-link.router-link-exact-active > i {
	@apply flex;
}
</style>
