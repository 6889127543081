<script setup lang="js">
import { useGlobalStore } from "@stores/global";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

const breakpoints = ref({
	0: {
		slidesPerView: 2,
	},
	800: {
		slidesPerView: 3,
	},
	1280: {
		slidesPerView: 3,
	},
});

const global = useGlobalStore();
</script>

<template>
	<div class="relative">
		<section class="container flex flex-col items-center gap-8 text-default lg:gap-0">
			<div class="flex h-[30vw] items-center justify-center">
				<div class="z-images flex flex-col items-center gap-2 uppercase lg:gap-8">
					<i class="icon-star-join text-[24px]" />
					<span> socials </span>
					<div class="d2 flex flex-row">
						<span class="font-heading-light">jo</span>
						<span class="font-heading">in </span>
						<span class="ml-[20px] font-heading-bold">the</span>
						<span class="ml-[20px] font-heading-bold">e</span>
						<span class="font-heading">th</span>
						<span class="font-heading-light">er</span>
					</div>
				</div>
			</div>
			<div class="w-full overflow-hidden">
				<swiper :breakpoints="breakpoints" class="flex" :modules="[Navigation, Pagination]" :navigation="false" :pagination="true">
					<swiper-slide v-for="social in global.socials" :key="social.avatar_url" class="p-2">
						<a :href="social.link" class="social-item-shadow relative flex flex-col items-start gap-5 rounded-big border border-border-default p-5 text-default transition-all duration-500 lg:w-full lg:min-w-[320px]" target="_blank">
							<img class="h-10" src="https://cdn.ether.site/images/icons/opensea.svg" :class="social.platform.icon" v-if="social.platform.slug == 'opensea'" />
							<h4 v-else><i :class="social.platform.icon" /></h4>
							<div class="label-medium flex flex-col gap-1 uppercase">
								<span class="text-purple-base" v-if="social.reach">
									{{ social.formatted_reach }}
								</span>
								{{ social.username }}
							</div>
							<i class="icon-button-edge absolute bottom-2 right-2 text-[6px] transition-all" />
						</a>
					</swiper-slide>
				</swiper>
			</div>
		</section>
		<img class="absolute left-0 top-[-20%] hidden w-full lg:block" src="https://cdn.ether.site/images/join-background-nodot.svg" alt="" />
	</div>
</template>

<style scoped>
.social-item-shadow:hover {
	box-shadow: 8px 8px #d8d2c8;
}

.social-item-shadow:hover .icon-button-edge {
	@apply text-xs;
}
</style>

<style>
.swiper-wrapper {
	padding-bottom: 2rem;
}
/* .swiper-pagination {
	padding-top: -20rem !important;
	background-color: red;
} */
.swiper-pagination-bullet {
	background-color: #d8d2c8 !important;
}
</style>
