<script setup>
import Supporter from "@sections/bounty/supporter.vue";
import { useGlobalStore } from "@stores/global.js";

import Loading from "@components/global/loading.vue";

const global = useGlobalStore();
</script>

<template>
	<Loading v-if="!global.supporters.length" />
	<section class="container flex flex-col gap-8 overflow-hidden lg:flex-row" v-else>
		<div class="flex w-full flex-col gap-6 lg:w-half">
			<div class="flex flex-col items-center gap-8 text-default lg:flex-row">
				<img class="h-40 w-40" src="https://cdn.ether.site/images/bounty-page/bounty-logo.png" alt="Bounty Logo" />
				<div class="flex flex-col items-center gap-4 text-center lg:items-start lg:text-left">
					<div class="flex uppercase">
						<h5 class="font-heading-semibold">wh</h5>
						<h5 class="">at is the boun</h5>
						<h5 class="font-heading-semibold">ty?</h5>
					</div>
					<p class="md">This badge is given to those who help push the Ether brand &amp; community in various ways towards greater heights.</p>
					<div class="h-[1px] w-full bg-border-default" />

					<div class="flex items-center gap-2">
						<i class="colored colored-icon fa-solid fa-sack" />
						<span class="colored colored-text label-medium">$5,000</span>
						<p class="md opacity-50">will be distributed to the chosen individual.</p>
					</div>
				</div>
			</div>
			<Supporter v-for="(supporter, i) in global.supporters" :supporter="supporter" :index="i" />
		</div>

		<div class="w-full lg:w-half">
			<img src="https://cdn.ether.site/images/bounty-page/main.png" class="w-full" alt="main image" />
		</div>
	</section>
</template>
