<script setup>
import DelimiterCrosses from "@sections/delimiter-crosses.vue";
import CollectionWithItems from "@sections/shop-list/collection-with-items.vue";
import { useGlobalStore } from "@stores/global";
import { useStoreStore } from "@stores/store";
import _ from "lodash";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed, ref, watch } from "vue";
import Markdown from "vue3-markdown-it";
import { useRoute } from "vue-router";

import Button from "@components/global/button.vue";
import Loading from "@components/global/loading.vue";
import Select from "@components/global/select.vue";
import SizeModal from "@components/modals/size-modal.vue";

const selectedImage = ref(null);
const shippingOpened = ref(false);

const global = useGlobalStore();
const store = useStoreStore();
const route = useRoute();
const loading = ref(false);
const product = ref({});
const requestProduct = ref(null);

const slug = computed(() => route.params.slug);

const singleVariant = computed(() => (product.value?.variants?.length > 1 ? false : true));
const optionSelections = ref({});
const formattedOptionSelections = computed(() => {
	const options = [];
	for (const [key, value] of Object.entries(optionSelections.value)) {
		options.push({
			name: key,
			value: value,
		});
	}
	return options;
});
const variant = computed(() => {
	return !_.isEmpty(product.value)
		? !singleVariant.value
			? (product.value.variants.filter(function (p) {
					return JSON.stringify(_.orderBy(p.selectedOptions, ["name"], ["asc"])) == JSON.stringify(_.orderBy(formattedOptionSelections.value, ["name"], ["asc"]));
				})[0] ?? {})
			: product.value.variants[0]
		: {};
});

function addToCart() {
	store.addToCart({
		id: variant.value.id,
	});
}

const leftSlider = ref({});
const onLeftSwiper = (swiper) => {
	leftSlider.value = swiper;
};

const rightSlider = ref({});
const onRightSwiper = (swiper) => {
	rightSlider.value = swiper;
};

function cancelProduct() {
	if (requestProduct.value) requestProduct.value.cancel();
}
function getProduct() {
	loading.value = true;
	optionSelections.value = {};
	product.value = {};
	cancelProduct();
	let axiosSource = axios.CancelToken.source();
	requestProduct.value = { cancel: axiosSource.cancel };
	axios
		.get(`/api/store/product/${route.params.slug}`, {
			cancelToken: axiosSource.token,
		})
		.then((p) => {
			loading.value = false;
			product.value = p.data;
			p.data.options?.forEach((opt) => {
				optionSelections.value[opt.name] = -1;
			});
		})
		.catch((e) => {});
}
getProduct();

watch(slug, async () => {
	getProduct();
});
</script>

<template>
	<Loading v-if="loading" />
	<template v-else>
		<div class="container" v-if="product">
			<div class="grid grid-cols-1 gap-14 lg:grid-cols-2">
				<div class="sticky top-[10rem] hidden h-fit flex-row gap-6 xl:flex">
					<div class="z-images flex flex-col items-center gap-4 text-default">
						<Button v-if="product.images > 3" @click="leftSlider.slidePrev()" class="outlined" :without-edge="true">
							<i class="icon-arrow-up" />
						</Button>
						<Swiper class="swiper-vertical z-images max-h-[316px] w-[100px] shrink-0 grow-0 select-none" @swiper="onLeftSwiper" @slideChange="onLeftSwiper" direction="vertical" :slides-per-view="Math.min(product.images, 3)" :space-between="12" :allowTouchMove="false" :loop="false">
							<SwiperSlide v-for="image in product.images" class="max-h-[96px] min-h-[96px]">
								<div @click="selectedImage = image" class="relative w-fit rounded-big border border-border-default bg-bg">
									<div class="w-fit cursor-pointer overflow-hidden rounded-big bg-neutral-700">
										<img :src="image" alt="photo" class="aspect-square w-[94px] object-contain" />
									</div>
								</div>
							</SwiperSlide>
						</Swiper>

						<Button v-if="product.images > 3" @click="leftSlider.slideNext()" class="outlined" :without-edge="true">
							<i class="icon-arrow-down" />
						</Button>
					</div>

					<div>
						<div class="relative z-images w-fit rounded-[16px] border border-border-default bg-bg p-3">
							<div class="z-images w-fit cursor-pointer rounded-big bg-neutral-700 p-6">
								<img :src="selectedImage ?? product.image" alt="photo" class="aspect-square w-full object-contain" />
							</div>
							<div class="absolute left-[-1px] top-[30%] h-[40%] w-[1px] bg-bg"></div>
							<div class="absolute right-[-1px] top-[30%] h-[40%] w-[1px] bg-bg"></div>
						</div>
					</div>

					<div class="absolute left-[-60vw] top-[30%] z-lines w-[100vw]">
						<DelimiterCrosses :rows="4" />
					</div>
				</div>

				<div class="relative gap-6 xl:hidden">
					<div class="relative z-images select-none rounded-[16px] border border-border-default bg-bg p-3">
						<div class="cursor-pointer rounded-big bg-neutral-700 p-6">
							<Swiper @swiper="onRightSwiper" @slideChange="onRightSwiper" :modules="[Navigation]" :navigation="true" :slides-per-view="1" :allowTouchMove="false" :loop="false">
								<SwiperSlide v-for="image in product.images">
									<div>
										<img class="aspect-square w-full object-contain" :src="image" alt="photo" />
									</div>
								</SwiperSlide>
							</Swiper>
						</div>
					</div>
					<div class="absolute left-[-1px] top-[30%] h-[40%] w-[1px] bg-bg"></div>
					<div class="absolute right-[-1px] top-[30%] h-[40%] w-[1px] bg-bg"></div>
				</div>

				<div class="flex flex-col gap-8 text-default">
					<div class="flex flex-col items-start gap-3 uppercase">
						<span class="label-small w-fit rounded-small border border-default px-1 py-0.5">Limited Collection</span>

						<h4>{{ product.title }}</h4>

						<p v-if="product.available" class="xl flex gap-2 font-semibold text-purple-base">
							<span v-if="product.compare_at_price" class="line-through opacity-30">
								{{ product.compare_at_price }}
							</span>
							{{ product.price }}
						</p>
						<p v-else class="xl text-red-base">Sold out</p>

						<div class="htmlmarkdown mt-5 normal-case opacity-70" v-html="product.descriptionHtml"></div>

						<div class="flex flex-col gap-2" v-if="product.metafield?.swatch?.length">
							<h4 class="text-2xl">Product Swatch</h4>
							<div class="flex gap-3 font-proto">
								<div class="flex items-center gap-2" v-for="swatch in JSON.parse(product.metafield?.swatch)">
									<div class="rounded-lg border-[1px] border-neutral-500 p-1">
										<div class="h-8 w-8 rounded" :style="{ 'background-color': swatch.hex }"></div>
									</div>
									<span>{{ swatch.name }}</span>
								</div>
							</div>
						</div>
					</div>

					<div class="h-[1px] w-full bg-border-default" />

					<div class="grid gap-6 xl:grid-cols-1" v-if="!singleVariant">
						<Select v-for="option in product.options" :key="option.name" :label="option.name" v-model="optionSelections[option.name]" :sizeGuide="product?.metafield?.sizeGuide ?? null">
							<option value="-1" disabled>Select {{ option.name }}</option>
							<option v-for="(value, i) in option.values" :key="value" :disabled="product.options?.length == 1 && !product.variants[i].available">
								{{ value }}
								{{ product.options?.length == 1 && !product.variants[i].available ? "(Sold Out)" : "" }}
							</option>
						</Select>
					</div>
					<Button class="default" icon="icon-shopping-basket text-[20px]" @click="addToCart" :disabled="!variant.id">
						<span class="md flex flex-row items-center justify-center gap-2">
							{{ product.available ? "Add to Cart" : "Sold Out" }}
						</span>
					</Button>

					<div class="h-[1px] w-full bg-border-default" />
					<!-- <div class="flex flex-col gap-4">
						<div class="flex cursor-pointer flex-row justify-between gap-4" @click="shippingOpened = !shippingOpened">
							<span class="label-medium text-sm opacity-70"> shipping & returns </span>
							<i :class="['icon-x text-[20px] transition-all', shippingOpened ? 'rotate-0' : 'rotate-45']"></i>
						</div>

						<p :class="['md overflow-hidden opacity-70 transition-all', shippingOpened ? 'max-h-[280px]' : 'max-h-0']">Mollit aliqua irure cillum nisi proident. Sunt commodo mollit consequat id occaecat nulla laboris. Nisi quis excepteur ipsum consequat incididunt cupidatat amet sit ad qui id in ex. Deserunt elit amet exercitation nostrud. Non Lorem ipsum commodo laborum enim. Esse amet ipsum commodo ea sunt labore consectetur</p>
					</div> -->
				</div>
			</div>
		</div>
		<CollectionWithItems :collection="store.collections[4]" :cta="{ title: 'shop all', show: true }" class="mb-20" />
		<SizeModal />
	</template>
</template>

<style scoped>
.size-button {
	@apply p-0;
}
</style>
