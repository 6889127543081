<script setup lang="ts">
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { ref } from "vue";

import Button from "@components/global/button.vue";
import Input from "@components/global/input.vue";

const global = useGlobalStore();
const auth = useAuthStore();
const processing = ref(false);
const success = ref(false);
const user = ref({
	remember: true,
});
const errors = ref({});

function submit(form) {
	form.preventDefault();
	if (!processing.value) {
		processing.value = true;
		axios
			.post("/forgot-password", user.value)
			.then(() => {
				processing.value = false;
				success.value = true;
			})
			.catch((error) => {
				processing.value = false;
				success.value = false;
				if (error.response.status === 422) {
					let errorBag = error.response?.data?.errors ?? {};
					errors.value = errorBag;
				}
			});
	}
}
</script>
<template>
	<div class="modal-overlay" v-if="global.modal?.name === 'forgot-password'" @click="global.closeModal()">
		<div @click="$event.stopPropagation()" class="modal-content max-w-[25rem]">
			<div class="flex items-center justify-between p-4 uppercase">
				<i class="icon-logo text-4xl" />
				<span class="label-medium">Forgotten Password</span>
				<Button class="close" without-edge="true" @click="global.closeModal()">
					<i class="fa fa-xmark" />
				</Button>
			</div>
			<div class="rounded-max border border-border-default bg-neutral-700 p-4 text-sm" v-if="success">Password reset email sent successfully, please check your inbox.</div>
			<form class="flex w-full flex-col gap-6 rounded-max border border-border-default bg-neutral-700 p-4" action="" @submit="submit" v-else>
				<Input label="Email" placeholder="Insert your email" max="255" :required="true" v-model="user.email" :error="errors.email && errors.email[0]" />
				<Button class="default" :processing="processing">Send Reset Link</Button>
				<span class="label-medium self-center">
					<span class="cursor-pointer text-purple-base" @click="global.openModal(['login'])"> Back to Login </span>
				</span>
				<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
			</form>
		</div>
	</div>
</template>
