<script setup>
import { format } from "@formkit/tempo";
import AchievementItem from "@sections/profile/achievement-item.vue";
import Collection from "@sections/profile/collection.vue";
import { useAuthStore } from "@stores/auth";
import { useGlobalStore } from "@stores/global";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

import Button from "@components/global/button.vue";
import Loading from "@components/global/loading.vue";
import AchievementsModal from "@components/modals/achievements-modal.vue";

import router from "../router";

const route = useRoute();
const global = useGlobalStore();
const auth = useAuthStore();
const shopCopied = ref(false);
const loading = ref(false);
const profile = ref({});
const requestProfile = ref(null);
const username = computed(() => route.params.username);

function cancelProfile() {
	if (requestProfile.value) requestProfile.value.cancel();
}
function getProfile() {
	loading.value = true;
	profile.value = {};
	cancelProfile();
	let axiosSource = axios.CancelToken.source();
	requestProfile.value = { cancel: axiosSource.cancel };
	axios
		.get(`/api/profile/${route.params.username}`, {
			cancelToken: axiosSource.token,
		})
		.then((p) => {
			loading.value = false;
			profile.value = p.data;
		})
		.catch((e) => {
			console.error("ee", e, e.response);
			if (e.response?.status == "404") {
				console.error("4040404040");
				router.replace({ name: "404" });
			}
			if (e.response.status == "301") {
				window.location.replace(e.response.data.user);
			}
		});
}
getProfile();

watch(username, async () => {
	getProfile();
});
</script>

<template>
	<Loading v-if="loading" />
	<div v-else>
		<div class="h-[15rem] bg-neutral-700">
			<div class="relative w-[50%] bg-neutral-700 text-bg">
				<i class="icon-logo absolute left-[-6vw] top-[-3rem] text-[480px]" />
			</div>
			<!-- <img
        class="absolute top-[-5%] z-images h-[25rem] w-full"
        src="https://cdn.ether.site/images/profile-page/profile-bg.png"
        alt="profile-background image" /> -->
		</div>
		<section class="container relative">
			<div class="absolute bottom-[90%] z-images max-w-[10rem] rounded-max bg-bg p-1">
				<img class="rounded-max" :src="profile.avatar ?? 'https://cdn.ether.site/images/profile-page/profile-image.png'" alt="profile image" />
			</div>
			<div class="flex h-full w-full flex-col gap-8 text-default lg:flex-row lg:gap-40">
				<div class="mt-20 flex flex-col gap-8 lg:mt-5 lg:w-half">
					<div class="label-small flex gap-2">
						<span class="profile-label"> Joined {{ format(profile.created_at, "short") }} </span>
						<!-- <span class="profile-label">
                {{ user.uid }}
            </span> -->
					</div>
					<div>
						<div class="flex items-center gap-6 uppercase">
							<h4>{{ profile.name ?? profile.username }}</h4>
							<div class="flex gap-3">
								<a :href="profile.social_twitter" v-if="profile.social_twitter" target="_blank">
									<i class="fab fa-x-twitter text-sm" />
								</a>
								<a :href="profile.social_instagram" v-if="profile.social_instagram" target="_blank">
									<i class="fab fa-instagram text-sm" />
								</a>
								<a :href="profile.social_youtube" v-if="profile.social_youtube" target="_blank">
									<i class="fab fa-youtube text-sm" />
								</a>
								<a :href="profile.social_facebook" v-if="profile.social_facebook" target="_blank">
									<i class="fab fa-facebook text-sm" />
								</a>
							</div>
						</div>
						<span class="label-small opacity-50">{{ profile.username }}</span>
					</div>
					<p class="md opacity-70" v-if="profile.biography">
						{{ profile.biography }}
					</p>
					<div class="flex gap-3">
						<!-- <Button
                without-edge="true"
                class="outlined relative"
                @click="shopCopied = true">
                <i :class="['icon', shopCopied ? 'icon-check' : 'icon-share']" />
                <div
                :class="[
                    'tooltip absolute top-[120%] z-images flex rounded-big bg-neutral-500 p-2 text-center',
                    !shopCopied && 'hidden',
                ]">
                <span class="label-medium relative z-images">Copied</span>
                </div>
            </Button> -->
						<Button class="default" icon="icon-settings icon" :to="{ name: 'account' }" v-if="auth.user?.username == profile.username"> Edit profile </Button>
					</div>
				</div>
				<div class="flex h-full flex-col rounded-max border border-border-default p-1 lg:w-half">
					<div class="label-medium w-full p-4">Achievements</div>
					<div class="z-images flex flex-col gap-6 rounded-max border border-border-default bg-neutral-700 p-4">
						<div class="my-grid gap-3" v-if="profile.badges?.length">
							<AchievementItem v-for="(badge, i) in profile.badges" :badge="badge" :key="i" />
							<!-- <div
                class="flex h-16 w-16 items-center justify-center rounded-full border border-border-default bg-bg"
                @click="global.openModal(['achievements', user.achievements])">
                <p>+12</p>
                </div> -->
						</div>
						<div class="text-center text-xs opacity-50" v-else>This user hasn't earned any badges yet.</div>
						<img src="https://cdn.ether.site/images/long-dots.svg" alt="image" />
					</div>
				</div>
			</div>
		</section>
		<section class="container">
			<div class="h-[1px] w-full bg-border-default" />
		</section>
		<!-- <Collection /> -->
		<AchievementsModal />
	</div>
</template>

<style scoped>
.profile-label {
	@apply rounded-small border border-border-default bg-neutral-700 px-2 py-1;
}

.btn .icon {
	@apply text-[20px];
}
.my-grid {
	display: grid;
	@apply visible grid gap-3;
	grid-template-columns: repeat(auto-fill, minmax(64px, 1fr));
}
</style>
