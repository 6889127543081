import { defineStore } from "pinia";

export const useProfileStore = defineStore("profile", {
	state: () => {
		return {
			orders: [
				{
					items: [
						{ name: "Ether hoodie 2024", total: 1 },
						{ name: "T-shirt Ether", total: 2 },
					],
					total: "$123.90",
					img: "https://cdn.ether.site/images/shop-page/item-image.png",
				},
			],
			bg_image: "https://cdn.ether.site/images/profile-page/profile-bg.png",
			profile_image: "https://cdn.ether.site/images/profile-page/profile-image.png",
			create_at: "01-01-2024",
			uid: "/0x324...",
			name: "Alisson Rochinski",
			username: "@rochinski",
			bio: "Proident sint ea cupidatat commodo officia consequat nisi exercitation laboris Lorem aute. Sit duis Lorem consequat sint amet sint mollit",
			socials: [
				{
					link: "",
					platform: { name: "youtube", icon: "fab fa-youtube" },
				},
				{
					link: "",
					platform: { name: "x", icon: "fab fa-x-twitter" },
				},
				{
					link: "",
					platform: { name: "instagram", icon: "fab fa-instagram" },
				},
			],
			achievements: [
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_a.svg",
					unlocked: false,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_b.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_c.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_d.svg",
					unlocked: true,
					title: "Achievement 1",
					description: "Nostrud in ut nostrud ut aliquip commodo eu aliquip est eu sit dolor officia Lorem sit nostrud eu velit non sunt dolor. Est tempor duis voluptate ess.",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_e.svg",
					unlocked: false,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_a.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_b.svg",
					unlocked: false,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_c.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_d.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_e.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_a.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_b.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_c.svg",
					unlocked: true,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_d.svg",
					unlocked: false,
					title: "Achievement",
					description: "lorem",
				},
				{
					url: "https://cdn.ether.site/images/profile-page/achievement_e.svg",
					unlocked: false,
					title: "Achievement",
					description: "Nostrud in ut nostrud ut aliquip commodo eu aliquip est eu sit dolor officia Lorem sit nostrud eu velit non sunt dolor. Est tempor duis voluptate ess.",
				},
			],
		};
	},
	getters: {},
	actions: {
		setup() {},
	},
});
