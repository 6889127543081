<script setup>
import { useGlobalStore } from "@stores/global.js";
import { ref, watch } from "vue";

import Button from "@components/global/button.vue";

const selected = ref(null);
const sorted = ref([]);

const global = useGlobalStore();

const handle = (item) => {
	if (!item.unlocked) return;

	selected.value = item;
};

watch(global, () => {
	if (global.modal?.name === "achievements") {
		sorted.value = global.modal.payload?.sort((a, b) => b.unlocked - a.unlocked) || [];

		selected.value = sorted.value[0];
	}
});
</script>
<template>
	<div class="modal-overlay" v-if="global.modal?.name === 'achievements'" @click="global.closeModal()">
		<div @click="$event.stopPropagation()" class="modal-content max-w-[50rem] lg:flex-row">
			<div class="order-2 flex w-full flex-col gap-10 rounded-max border border-border-default bg-neutral-700 p-4 lg:order-1 lg:w-[60%] lg:p-8">
				<div class="label-medium">Achievements (18/25)</div>
				<div class="label-medium flex flex-wrap gap-3">
					<div
						v-for="item in sorted"
						:key="item.title"
						@click="handle(item)"
						class="flex h-16 w-16 items-center justify-center rounded-full border border-border-default bg-bg">
						<img :src="item.url" alt="achievement icon" :class="[!item.unlocked && 'opacity-30']" />
					</div>
				</div>
				<img class="w-full" alt="im" src="https://cdn.ether.site/images/long-dots.svg" />
			</div>
			<div class="relative order-1 flex flex-col items-center gap-8 p-4 lg:order-2 lg:w-[40%] lg:p-8">
				<div class="absolute right-4 top-4">
					<Button class="close" without-edge="true" @click="global.closeModal()">
						<i class="fa fa-xmark" />
					</Button>
				</div>
				<div class="flex aspect-square w-full max-w-[280px] items-center justify-center rounded-full bg-neutral-900 p-12">
					<img class="w-full" :src="selected?.url" alt="achievement icon" />
				</div>
				<div class="flex flex-col gap-2 text-center">
					<span class="label-medium">{{ selected?.title }}</span>
					<p class="md text-center">{{ selected?.description }}</p>
				</div>
			</div>
		</div>
	</div>
</template>
