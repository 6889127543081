<script setup lang="ts">
import NewsCard from "@sections/news/news-card.vue";
import { useGlobalStore } from "@stores/global";
import { Swiper, SwiperSlide } from "swiper/vue";
import { ref } from "vue";

import Button from "@components/global/button.vue";

const categories = ref([
  { title: "all-news", value: "" },
  { title: "fashion", value: "fashion" },
  { title: "partnerships", value: "partnerships" },
  { title: "updates", value: "updates" },
  { title: "experiences", value: "experiences" },
]);

const selectedCategory = ref("");

const global = useGlobalStore();
</script>

<template>
  <section class="lg:gap-15 container flex flex-col gap-10 text-default">
    <div
      class="relative flex flex-col items-center lg:mt-16 lg:block lg:columns-2 lg:gap-x-[10%]">
      <div
        class="absolute bottom-0 left-[50%] top-0 hidden translate-x-[-50%] lg:block">
        <div
          class="h-[100%] w-[1px] border-l border-dashed border-border-default">
          <div
            class="sticky top-[15rem] ml-[-5px] h-2 w-2 rotate-45 bg-purple-base" />
        </div>
      </div>

      <div class="relative lg:mb-32 mb-8 max-w-[100%] p-4">
        <div class="overflow-hidden">
          <swiper slides-per-view="auto">
            <swiper-slide v-for="category in categories">
              <Button
                @click="selectedCategory = category.value"
                :without-edge="true">
                <i
                  v-if="selectedCategory === category.value"
                  class="icon-button-edge text-[6px]" />
                <span class="hover:opacity-70">{{ category.title }} </span>
              </Button>
            </swiper-slide>
          </swiper>
        </div>
        <div class="categories-border" />
      </div>

      <div v-for="item of global.news" :key="item.title" class="lg:mb-32 mb-8">
        <Button
          :to="{ name: 'article', params: { slug: item.slug } }"
          :without-edge="true">
          <NewsCard :item="item" />
        </Button>
      </div>
    </div>
    <Button class="outlined text-default" icon="icon-arrow-down text-sm">
      Load more
    </Button>
  </section>
</template>

<style scoped>
.swiper-slide {
  width: fit-content;
}

.categories-border {
  @apply absolute right-[-2rem] top-[-2rem] hidden h-20 w-40 rounded-tr-[16px] border-r border-t border-r-default border-t-default lg:block;
}

.categories-border::after {
  position: absolute;
  top: 16px;
  right: 16px;
  content: "";
  display: block;
  width: 3px;
  height: 3px;
  background: #9e76b2;
}
</style>
