<script setup>
import DelimiterSection from "@sections/delimiter-crosses.vue";
import { computed, onMounted, ref, watch } from "vue";

const props = defineProps(["date"]);
const remaining = ref(0);
const countdown = ref({
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
});
const timeinterval = ref(null);

function timer() {
	let t = Date.parse(new Date(props.date)) - Date.parse(new Date());
	remaining.value = t / 1000;
	if (t >= 0) {
		countdown.value.seconds = Math.floor((t / 1000) % 60);
		countdown.value.minutes = Math.floor((t / 1000 / 60) % 60);
		countdown.value.hours = Math.floor((t / (1000 * 60 * 60)) % 24);
		countdown.value.days = Math.floor(t / (1000 * 60 * 60 * 24));
	} else {
		clearTimeout(timeinterval.value);
		countdown.value.days = countdown.value.hours = countdown.value.minutes = countdown.value.seconds = 0;
	}
}
function pad(n) {
	return (n < 10 ? "0" : "") + n;
}

const colorDays = computed(() => {
	return countdown.value.days > 0 ? "#9E76B2" : "#22272C";
});
const colorHours = computed(() => {
	return countdown.value.days > 0 ? "#FFFFFF" : countdown.value.hours > 0 ? "#9E76B2" : "#22272C";
});
const colorMinutes = computed(() => {
	return countdown.value.days > 0 || countdown.value.hours > 0 ? "#FFFFFF" : countdown.value.minutes > 0 ? "#F95046" : "#22272C";
});
const colorSeconds = computed(() => {
	return countdown.value.days > 0 || countdown.value.hours > 0 || countdown.value.minutes > 0 ? "#FFFFFF" : countdown.value.seconds > 0 ? "#F95046" : "#22272C";
});

onMounted(() => {
	timer();
	timeinterval.value = setInterval(timer, 1000);
});
watch(event, async () => {
	timer();
	timeinterval.value = setInterval(timer, 1000);
});
</script>

<template>
	<div class="container">
		<section class="flex h-[calc(100vh-525px)] flex-col justify-center">
			<!-- <div class="-mb-5 text-center font-proto text-lg uppercase text-white/30">Collection Drop</div> -->
			<DelimiterSection :rows="2" class="-mt-10" />
			<div class="grid grid-cols-11">
				<div class="digit">
					<div :style="{ color: colorDays }">{{ pad(countdown.days) }}</div>
					<span>Days</span>
				</div>
				<div class="delimiter">:</div>
				<div class="digit">
					<div :style="{ color: colorHours }">{{ pad(countdown.hours) }}</div>
					<span>Hours</span>
				</div>
				<div class="delimiter">:</div>
				<div class="digit">
					<div :style="{ color: colorMinutes }">{{ pad(countdown.minutes) }}</div>
					<span>Minutes</span>
				</div>
				<div class="delimiter">:</div>
				<div class="digit">
					<div :style="{ color: colorSeconds }">{{ pad(countdown.seconds) }}</div>
					<span>Seconds</span>
				</div>
			</div>
		</section>
	</div>
</template>

<style>
.digit {
	@apply col-span-2 flex flex-col items-center justify-center gap-1 font-heading;
}
.digit div {
	@apply text-[9vw] leading-[10vw] 2xl:text-[9rem] 2xl:leading-[10rem];
}
.digit span {
	@apply font-proto text-sm uppercase tracking-wide text-white/30 lg:text-lg;
}
.delimiter {
	@apply flex flex-col items-center justify-center pb-10 font-proto text-[9vw] leading-[9vw] text-white/10 2xl:text-[9rem] 2xl:leading-[7rem];
}

@-webkit-keyframes slide {
	from {
		-webkit-transform: translateX(0);
	}
	to {
		-webkit-transform: translateX(46px);
	}
}

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(46px);
	}
}

.stripes {
	@apply absolute -left-11 bottom-0 right-0 top-0;
	background: -webkit-repeating-linear-gradient(-55deg, #141a20 1px, transparent 2px, transparent 11px, #141a20 12px, #141a20 20px);
	background: repeating-linear-gradient(-55deg, #141a20 1px, transparent 2px, transparent 11px, #141a20 12px, #141a20 20px);
	-webkit-animation-name: slide;
	-webkit-animation-duration: 1.5s;
	-webkit-animation-timing-function: linear;
	-webkit-animation-iteration-count: infinite;
	animation-name: slide;
	animation-duration: 1.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
</style>
