<script setup lang="ts">
import { computed, onMounted, ref } from "vue";

defineProps(["rows"]);

const width = ref(document.documentElement.clientWidth);

// media-queries for content
const screenWidth = computed(() => {
	if (width.value > 1536) return 1536;
	if (width.value > 1280) return 1280;
	if (width.value > 1024) return 1024;
	if (width.value > 768) return 768;
	if (width.value > 640) return 640;
	return width.value;
});

// 32px is padding
const itemsPerLine = computed(() => Math.floor((screenWidth.value - 32) / 32));

const items = computed(() => new Array(Math.floor(itemsPerLine.value)).fill(1));

const updateWidth = () => {
	width.value = document.documentElement.clientWidth;
};

onMounted(() => {
	window.addEventListener("resize", updateWidth);
});
</script>

<template>
	<section class="container">
		<div class="flex" v-for="row in rows">
			<svg v-for="(_, index) in items" :key="index" class="transition-all duration-300 hover:rotate-45" :width="32" :height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="16" cy="16" r="2.5" stroke="#292928" />
				<path d="M6.10059 25.8995L11.7574 20.2426" stroke="#292928" stroke-width="2" />
				<path d="M20.2432 11.7574L25.9 6.10051" stroke="#292928" stroke-width="2" />
				<path d="M25.8994 25.8995L20.2426 20.2426" stroke="#292928" stroke-width="2" />
				<path d="M11.7568 11.7574L6.09998 6.1005" stroke="#292928" stroke-width="2" />
			</svg>
		</div>
	</section>
</template>
