<script setup lang="ts">
import ProductItem from "@sections/shop-list/product-item.vue";
import { useStoreStore } from "@stores/store";
import { useRouter } from "vue-router";

const store = useStoreStore();
const router = useRouter();
</script>
<template>
	<section class="container flex flex-wrap justify-center gap-6">
		<div v-for="product in store.collection($route.params.id)?.products" :key="product.title">
			<ProductItem :item="product" />
		</div>
	</section>
</template>
